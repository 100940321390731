<template>
  <transition
    name="fade"
    class="w-100 d-flex row justify-content-between px-1"
    style="margin: 0px"
  >
    <div>
      <b-col>
        <!-- <h2>{{ $t(`pages.${pagename}.pagetitle`) }}</h2> -->
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-table
            ref="growthcurveTable"
            :items="tabledata"
            :fields="fields"
            sort-by="agedays"
            :sort-desc="false"
            sort-icon-right
            striped
            :fixed="false"
            responsive
            class="mb-0"
          >
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>

            <!-- A virtual column -->
            <!-- <template #cell(index)="data">
              <div class="text-center">
                {{ data.index + 1 }}
              </div>
            </template> -->
            <template #cell(agedays)="data">
              <div class="d-flex justify-content-center align-items-center">
                <b-form-input
                  v-if="data.value !== 3650000"
                  v-model="data.value"
                  type="number"
                  style="width: 75px; text-align: center"
                  @blur="inputHandler($event, data.index, data.field.key, 1)"
                />
                <b-form-input
                  v-if="data.value === 3650000"
                  :value="$t('common.infinitas')"
                  type="text"
                  style="width: 75px; text-align: center"
                />
              </div>
            </template>

            <template #cell(targetvalue)="data">
              <div class="d-flex justify-content-center align-items-center">
                <b-form-input
                  :value="data.value"
                  type="number"
                  style="width: 75px; text-align: center"
                  @blur="inputHandler($event, data.index, data.field.key, 2)"
                />
              </div>
            </template>

            <template #cell()="data">
              <div
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <b-form-input
                  :value="data.value"
                  type="number"
                  style="width: 75px; text-align: center"
                  @blur="inputHandler($event, data.index, data.field.key, 2)"
                />
              </div>
            </template>
            <template #cell(add)="data">
              <b-button
                v-if="showaddbutton(data.index) == true"
                variant="primary"
                @click="addRowHandler(data.index)"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </b-button>
            </template>
            <template #cell(delete)="data">
              <b-button
                v-if="showdeletebutton(data.index) == true"
                variant="danger"
                @click="removeRowHandler(data.index)"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </b-button>
            </template>
          </b-table>
          <b-button
            variant="success"
            style="margin: 10px 0px"
            @click="savetabledata()"
          >
            {{ $t(`common.save`) }}
          </b-button>
        </div>
      </b-col>
    </div>
  </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  VBTooltip,
  BCardBody,
  BTable,
  BFormCheckbox,
  BBadge,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'
import { Tientech_getDefauleGrowthcurve } from '@/libs/tientech/growthcurve'

export default {
  components: {
    // BCard,
    // BAvatar,
    // BRow,
    BCol,
    // BFormCheckbox,
    // BBadge,
    BButton,
    BFormInput,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondevices: {
      type: Array,
      default: () => [],
    },
    regiontype: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      pagename: 'devicecontrolsetting_growthcurve',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      tabledata: [],
    }
  },
  computed: {
    fields() {
      const tmpArray = []
      try {
        // tmpArray.push({
        //   key: 'index',
        //   label: '#',
        //   type: 'number',
        //   thStyle: { 'max-width': '30px' },
        // })
        tmpArray.push({
          key: 'agedays',
          label: this.$t('common.agedays'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'targetvalue',
          label: this.$t('common.temperature') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'heatup',
          label: this.$t('common.heatup') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'cooling',
          label: this.$t('common.cooling') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'humidity',
          label: this.$t('common.humidity') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'house_ceiling',
          label: this.$t('common.house_ceiling'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'aircoolingfactor',
          label: this.$t('common.aircoolingfactor'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'minventilationtime',
          label: this.$t('common.minventilationtime'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'minventilationspeed',
          label: this.$t('common.minventilationspeed'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({
          key: 'maxventilationspeed',
          label: this.$t('common.maxventilationspeed'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        tmpArray.push({ key: 'add', label: '', width: '84px' })
        tmpArray.push({ key: 'delete', label: '' })
        const tmpregiondevicesArray = this.regiondevices

        tmpregiondevicesArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          if (a_name < b_name) {
            return -1
          }
          if (a_name > b_name) {
            return 1
          }
          return 0
        })

        tmpArray.push({ key: 'add', label: '', width: '84px' })
        tmpArray.push({ key: 'delete', label: '' })
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
      clearInterval(this.interval_devicecloud_getdevice)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      clearInterval(this.interval_devicecloud_getdevice)
      document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    savetabledata() {
      try {
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          data: this.tabledata,
        }
        this.$socket.emit('casecloud_setcasesregion_growthcurve', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 2000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    inputHandler(event, index, key, pointlength) {
      let iserror = false
      const value = event.target.value
      if (value === undefined || value === null || value === '') {
        this.$swal({
          icon: 'error',
          title: this.$t('error_common.input_error_null'),
          showConfirmButton: true,
          timer: 2000,
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        iserror = true
      }
      if (Number.isNaN(parseFloat(value)) === true) {
        this.$swal({
          icon: 'error',
          title: this.$t('error_common.input_error'),
          showConfirmButton: true,
          timer: 2000,
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        iserror = true
      }
      if (iserror === false && parseFloat(value) >= 0) {
        let isnum =
          /^0\.([0-9]|\d[0-9])$|^[0-9]\d{0,8}\.\d{0,2}$|^[0-9]\d{0,8}$/
        if (pointlength === 0) {
          isnum = /^-?\d+$/
        } else if (pointlength === 1) {
          isnum = /^-?\d+\.?\d{0,1}$/
        } else {
          isnum = /^0\.([0-9]|\d[0-9])$|^[0-9]\d{0,8}\.\d{0,2}$|^[0-9]\d{0,8}$/
        }
        console.log(value)
        if (!isnum.test(value)) {
          this.$swal({
            icon: 'error',
            title: this.$t('error_common.input_error'),
            showConfirmButton: true,
            timer: 2000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          iserror = true
        }
      }

      this.tabledata.sort((a, b) => {
        const a_value = parseFloat(a.agedays)
        const b_value = parseFloat(b.agedays)
        if (a_value < b_value) {
          return -1
        }
        if (a_value > b_value) {
          return 1
        }
        return 0
      })

      if (iserror !== true) {
        this.$set(this.tabledata, index, {
          ...this.tabledata[index],
          [key]: parseFloat(value),
        })
      } else {
        this.$set(this.tabledata, index, this.tabledata[index])
        this.$refs.growthcurveTable.refresh()
      }
      event.currentTarget.value = parseFloat(this.tabledata[index][key])
      this.$forceUpdate()
    },
    showdeletebutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }
      if (this.tabledata[index].targetvalue === 0) {
        return false
      }
      return true
    },
    showaddbutton(index) {
      if (this.tabledata[index].agedays === 3650000) {
        return false
      }
      if (this.tabledata[index + 1] !== undefined) {
        if (
          parseFloat(this.tabledata[index + 1].agedays) ===
          parseFloat(this.tabledata[index].agedays) + 1
        ) {
          return false
        }
      }
      return true
    },
    addRowHandler(index) {
      const newRow = { ...this.tabledata[index] }
      newRow.agedays = parseFloat(this.tabledata[index].agedays) + 1
      this.tabledata.push(newRow)
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.agedays)
        const b_targetvalue = parseFloat(b.agedays)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
    },
    removeRowHandler(index) {
      this.tabledata = this.tabledata.filter((item, i) => i !== index)
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = this.$route.params.casepn
        this.regionpn = this.$route.params.regionkey
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        const vuethis = this
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_growthcurve',
            this.socketevent_casecloud_getcaseregion_growthcurve
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesregion_growthcurve')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_growthcurve(params) {
      try {
        const vuethis = this
        if (params === undefined || params.type === 'error') {
          return
        }
        if (
          params.callback_params !==
          `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`
        ) {
          return
        }
        if (params.msg !== undefined) {
          if (params.msg.data !== undefined) {
            this.tabledata = params.msg.data
            this.tabledata.sort((a, b) => {
              const a_targetvalue = parseFloat(a.agedays)
              const b_targetvalue = parseFloat(b.agedays)
              if (a_targetvalue < b_targetvalue) {
                return -1
              }
              if (a_targetvalue > b_targetvalue) {
                return 1
              }
              return 0
            })
          }
        }
        if (this.tabledata === undefined || this.tabledata === null) {
          this.tabledata = []
        }

        if (this.tabledata.length === 0) {
          switch (this.regiontype) {
            case 'barn_pig':
            case 'barn_pig_nursery':
            case 'barn_pig_fattening':
            case 'barn_pig_finish':
            case 'barn_pig_childbirth':
            case 'barn_pig_Breeding':
              // this.tabledata = Tientech_getDefauleGrowthcurve('pig')
              this.tabledata = Tientech_getDefauleGrowthcurve('empty')
              break
            case 'barn_chick':
            case 'barn_chick_egg':
            case 'barn_chicken_whitebroiler':
            case 'barn_chicken_coloredbroiler':
              // this.tabledata = Tientech_getDefauleGrowthcurve('broiler')
              this.tabledata = Tientech_getDefauleGrowthcurve('empty')
              break
            default:
              this.tabledata = Tientech_getDefauleGrowthcurve('empty')
              break
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    getData() {
      try {
        const vuethis = this
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          callback_params: `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`,
        }

        this.$socket.emit('casecloud_getcasesregion_growthcurve', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss"></style>
