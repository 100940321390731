<template>
    <transition
        name="fade"
        class="w-100 d-flex row justify-content-between px-1"
        style="margin: 0px"
    >
        <div>
            <b-col>
                <!-- <div>feeding</div> -->
                <div>
                    <b-table
                        ref="getFanTable"
                        :items="items"
                        :fields="fields"
                        striped
                        responsive
                        class="mb-0"
                    >
                        <template #cell(show_details)="row">
                            <b-form-checkbox
                                v-model="row.detailsShowing"
                                @change="row.toggleDetails"
                            >
                                {{ row.detailsShowing ? 'Hide' : 'Show' }}
                            </b-form-checkbox>
                        </template>

                        <!-- full detail on click -->
                        <template #row-details="row">
                            <b-card>
                                <b-row class="mb-2">
                                    <b-col md="4" class="mb-1">
                                        <strong>Full Name : </strong>{{ row.item.full_name }}
                                    </b-col>
                                    <b-col md="4" class="mb-1">
                                        <strong>Post : </strong>{{ row.item.post }}
                                    </b-col>
                                    <b-col md="4" class="mb-1">
                                        <strong>Email : </strong>{{ row.item.email }}
                                    </b-col>
                                    <b-col md="4" class="mb-1">
                                        <strong>City : </strong>{{ row.item.city }}
                                    </b-col>
                                    <b-col md="4" class="mb-1">
                                        <strong>Salary : </strong>{{ row.item.salary }}
                                    </b-col>
                                    <b-col md="4" class="mb-1">
                                        <strong>Age : </strong>{{ row.item.age }}
                                    </b-col>
                                </b-row>

                                <b-button
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="row.toggleDetails"
                                >
                                    Hide Details
                                </b-button>
                            </b-card>
                        </template>

                        <template #cell(avatar)="data">
                            <b-avatar :src="data.value" />
                        </template>

                        <template #cell(status)="data">
                            <div>
                                {{ data.value }}
                            </div>
                        </template>

                        <template #cell(fan1)="data">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center"
                                @click="toggle(data, 'fan1')"
                            >
                                <b-avatar
                                    class=""
                                    :variant="
                                        data.value == 1 ? 'light-success' : 'light-secondary'
                                    "
                                    size="30"
                                >
                                    <div>
                                        <font-awesome-icon
                                            v-if="data.value === 1"
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class="fa-spin"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class=""
                                        />
                                    </div>
                                </b-avatar>
                                <div>{{ 'fan4' }}</div>
                            </div>
                        </template>
                        <template #cell(fan2)="data">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center"
                            >
                                <b-avatar
                                    class=""
                                    :variant="
                                        data.value == 1 ? 'light-success' : 'light-secondary'
                                    "
                                    size="30"
                                >
                                    <div @click="toggle(data, 'fan2')">
                                        <font-awesome-icon
                                            v-if="data.value === 1"
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class="fa-spin"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class=""
                                        />
                                    </div>
                                </b-avatar>
                                <div>{{ 'fan4' }}</div>
                            </div>
                        </template>
                        <template #cell(fan3)="data">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center"
                            >
                                <b-avatar
                                    class=""
                                    :variant="
                                        data.value == 1 ? 'light-success' : 'light-secondary'
                                    "
                                    size="30"
                                >
                                    <div @click="toggle(data, 'fan3')">
                                        <font-awesome-icon
                                            v-if="data.value === 1"
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class="fa-spin"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class=""
                                        />
                                    </div>
                                </b-avatar>
                                <div>{{ 'fan4' }}</div>
                            </div>
                        </template>
                        <template #cell(fan4)="data">
                            <div
                                class="d-flex flex-column justify-content-center align-items-center"
                            >
                                <b-avatar
                                    class=""
                                    :variant="
                                        data.value == 1 ? 'light-success' : 'light-secondary'
                                    "
                                    size="30"
                                >
                                    <div @click="toggle(data, 'fan4')">
                                        <font-awesome-icon
                                            v-if="data.value === 1"
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class="fa-spin"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            style="font-size: 20px"
                                            icon="fa-solid fa-fan fa-lg"
                                            class=""
                                        />
                                    </div>
                                </b-avatar>
                                <div>{{ 'fan4' }}</div>
                            </div>
                        </template>
                    </b-table>
                </div>

                <template #code>
                    {{ codeRowDetailsSupport }}
                </template>
            </b-col>
        </div>
    </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  VBTooltip,
  BCardBody,
  BTable,
  BFormCheckbox,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

export default {
  components: {
    BCard,
    BAvatar,
    BCol,
    BFormCheckbox,
    // BBadge,
    BButton,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {},
  data() {
    return {
      pagename: 'devicecontrolsetting',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      fields: ['show_details', 'id', 'fan1', 'fan2', 'fan3'],
      /* eslint-disable global-require */
      items: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/10-small.png'),
          devicetype: 'fan',
          full_name: "Korrie O'Crevy",
          post: 'Nuclear Power Engineer',
          email: 'kocrevy0@thetimes.co.uk',
          city: 'Krasnosilka',
          start_date: '09/23/2016',
          salary: '$23896.35',
          age: '61',
          experience: '1 Year',
          status: 2,
          fan1: 1,
          fan2: 1,
          fan3: 1,
          fan4: 1,
        },
        {
          id: 2,
          avatar: require('@/assets/images/avatars/1-small.png'),
          full_name: 'Bailie Coulman',
          post: 'VP Quality Control',
          email: 'bcoulman1@yolasite.com',
          city: 'Hinigaran',
          start_date: '05/20/2018',
          salary: '$13633.69',
          age: '63',
          experience: '3 Years',
          status: 2,
          fan1: 1,
          fan2: 0,
          fan3: 0,
          fan4: 1,
        },
        {
          id: 3,
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Stella Ganderton',
          post: 'Operator',
          email: 'sganderton2@tuttocitta.it',
          city: 'Golcowa',
          start_date: '03/24/2018',
          salary: '$13076.28',
          age: '66',
          experience: '6 Years',
          status: 5,
          fan: 1,
        },
      ],
      /* eslint-disable global-require */
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {},
  created() {
    try {
      // this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      // this.socketioevent_initialize()
      // this.page_initialize()
      // this.getData()
      clearInterval(this.interval_devicecloud_getdevice)
      this.interval_devicecloud_getdevice = setInterval(() => {
        // this.getData()
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      clearInterval(this.interval_devicecloud_getdevice)
      // document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          // this.sockets.subscribe(
          //   'reply casecloud_getcasesmcdevice',
          //   this.socketevent_casecloud_getcasesmcdevice
          // )
        } else {
          // this.sockets.unsubscribe('reply casecloud_getcasesmcdevice')
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">

</style>
