<template>
  <transition
    name="fade"
    class="w-100 d-flex row justify-content-between px-1"
    style="margin: 0px"
  >
    <div>
      <!-- system -->
      <b-col>
        <h3>{{ $t(`common.system`) + $t(`common.alarm`) }}</h3>
        <hr />
        <b-card>
          <div class="d-flex justify-content-between">
            <div>
              {{
                $t(`pages.devicecontrolsetting_alarm.system.msg1`) +
                $t(`common.isenable`)
              }}
            </div>
            <b-button variant="gradient-success">
              {{ $t(`common.opened`) }}
            </b-button>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`pages.devicecontrolsetting_alarm.system.msg2`) }}</div>
            <b-form-timepicker
              v-model="offline_time"
              locale="zh"
              style="width: 120px"
              placeholder="00:00"
            />
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`pages.devicecontrolsetting_alarm.system.msg3`) }}</div>
            <b-form-timepicker
              v-model="offline_time"
              locale="zh"
              style="width: 120px"
              placeholder="00:00"
            />
          </div>
        </b-card>

        <hr />
      </b-col>
      <!-- temperature -->
      <b-col>
        <h3>{{ $t(`common.temperature`) + $t(`common.alarm`) }}</h3>
        <hr />
        <b-card>
          <template #header>
            <h4 class="mb-0">{{ $t(`common.temperature`) }}</h4>
          </template>
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.isenable`) }}</div>
            <b-button variant="gradient-success">
              {{ $t(`common.opened`) }}
            </b-button>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.max_value`) }}</div>
            <b-form-input
              v-model="max_value"
              type="text"
              style="width: 75px; text-align: center"
            />
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.min_value`) }}</div>
            <b-form-input
              v-model="min_value"
              type="text"
              style="width: 75px; text-align: center"
            />
          </div>
        </b-card>
        <hr />
      </b-col>
      <!-- hygrometers -->
      <b-col>
        <h3>{{ $t(`common.humidity`) + $t(`common.alarm`) }}</h3>
        <hr />
        <b-card>
          <template #header>
            <h4 class="mb-0">{{ $t(`common.humidity`) }}</h4>
          </template>
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.isenable`) }}</div>
            <b-button variant="gradient-success">
              {{ $t(`common.opened`) }}
            </b-button>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.max_value`) }}</div>
            <b-form-input
              v-model="max_value"
              type="text"
              style="width: 75px; text-align: center"
            />
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.min_value`) }}</div>
            <b-form-input
              v-model="min_value"
              type="text"
              style="width: 75px; text-align: center"
            />
          </div>
        </b-card>
        <hr />
      </b-col>
      <!--  -->
      <!-- carbondioxides -->
      <b-col>
        <h3>{{ $t(`common.carbondioxide`) + $t(`common.alarm`) }}</h3>
        <hr />
        <b-card>
          <template #header>
            <h4 class="mb-0">{{ $t(`common.carbondioxide`) }}</h4>
          </template>
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.isenable`) }}</div>
            <b-button variant="gradient-success">
              {{ $t(`common.opened`) }}
            </b-button>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.max_value`) }}</div>
            <b-form-input
              v-model="max_value"
              type="text"
              style="width: 75px; text-align: center"
            />
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>{{ $t(`common.min_value`) }}</div>
            <b-form-input
              v-model="min_value"
              type="text"
              style="width: 75px; text-align: center"
            />
          </div>
        </b-card>
        <hr />
      </b-col>
      <!--  -->
    </div>
  </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  VBTooltip,
  BCardBody,
  BTable,
  BFormCheckbox,
  BBadge,
  BButton,
  BFormInput,
  BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

export default {
  components: {
    BCard,
    BAvatar,
    // BRow,
    BCol,
    // BFormCheckbox,
    // BBadge,
    BButton,
    BFormInput,
    BTable,
    BFormTimepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondevices: {
      type: Array,
      default: () => [],
    },
    thermometers: {
      type: Array,
      default: () => [],
    },
    smcs: {
      type: Array,
      default: () => [],
    },
    hygrometers: {
      type: Array,
      default: () => [],
    },
    negativepressures: {
      type: Array,
      default: () => [],
    },
    carbondioxides: {
      type: Array,
      default: () => [],
    },
    inverters: {
      type: Array,
      default: () => [],
    },
    alarms: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagename: 'devicecontrolsetting_alarm',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      offline_time: '01:00',
      notification_frequency: '01:00',
      max_value: 0,
      min_value: 0,
      tabledata: [],
      parameter_tabledata: [
        {
          name: 'p_value',
          value: 3,
        },
        {
          name: 'min_time',
          value: 10,
        },
        {
          name: 'max_time',
          value: 60,
        },
        {
          name: 'operationtime',
          from: '00:00',
          till: '00:00',
        },
        {
          name: 'repeat_time',
          value: 480,
        },
        {
          name: 'humiditylimit',
          value: 95,
        },
      ],
      temp_tabledata: [],
    }
  },
  computed: {},
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
      clearInterval(this.interval_devicecloud_getdevice)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      clearInterval(this.interval_devicecloud_getdevice)
      document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    toggletemp(value, index, key) {
      try {
        if (key.indexOf('thermometer') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 0
          }
          if (value === 0) {
            fanstatus = 1
          } else if (value === 1) {
            fanstatus = 0
          }
          this.temp_tabledata[index][key] = fanstatus
        }

        this.$set(this.temp_tabledata, index, this.temp_tabledata[index])
        this.$emit('input', this.temp_tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    togglewetpad(value, index, key) {
      try {
        if (key.indexOf('wetpad') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 1
          }
          if (value === 0) {
            fanstatus = 1
          } else if (value === 1) {
            fanstatus = 2
          } else if (value === 2) {
            fanstatus = 0
          }
          this.tabledata[index][key] = fanstatus
        }
        this.tabledata.sort((a, b) => {
          const a_targetvalue = parseFloat(a.targetvalue)
          const b_targetvalue = parseFloat(b.targetvalue)
          if (a_targetvalue < b_targetvalue) {
            return -1
          }
          if (a_targetvalue > b_targetvalue) {
            return 1
          }
          return 0
        })
        this.$set(this.tabledata, index, this.tabledata[index])
        this.$emit('input', this.tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    savetemp_tabledatadata() {
      try {
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          thermometers: this.thermometers,
          data: this.tabledata,
          temp_data: this.temp_tabledata,
          parameter_data: this.parameter_tabledata,
        }
        this.$socket.emit('casecloud_setcasesregion_cooling', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    savetabledata() {
      try {
        this.parameter_tabledata.forEach((element, index) => {
          if (element.name === 'operationtime') {
            if (
              new Date(`1990/01/01 ${element.from}`).getTime() >
              new Date(`1990/01/01 ${element.till}`).getTime()
            ) {
              const tmpdata = this.parameter_tabledata[index].till
              this.parameter_tabledata[index].till = element.from
              this.parameter_tabledata[index].from = tmpdata
            }
          }
        })
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          thermometers: this.thermometers,
          data: this.tabledata,
          temp_data: this.temp_tabledata,
          parameter_data: this.parameter_tabledata,
        }
        this.$socket.emit('casecloud_setcasesregion_cooling', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    inputHandler(value, index, key, datatype, enable) {
      if (key === 'targetvalue') {
        const found = this.tabledata.findIndex((element, arrayindex) => {
          if (parseFloat(element.targetvalue) === parseFloat(value)) {
            return arrayindex
          }
          return false
        })
        if (found === -1) {
          this.tabledata[index].targetvalue = value
          this.tabledata[index].agename = value
        } else {
          this.tabledata.splice(found, 1)
        }
      } else {
        this.tabledata[index][key] = value
      }
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    timepickerinputHandler(event, index, key, datatype, enable) {
      const value = event.target.value
      // console.log(value)
      this.parameter_tabledata[index][key] = value
      this.$set(
        this.parameter_tabledata,
        index,
        this.parameter_tabledata[index]
      )
      this.$emit('input', this.parameter_tabledata)
    },
    parameterinputHandler(event, index, key, datatype, enable) {
      const value = event.target.value
      this.parameter_tabledata[index][key] = value
      this.$set(
        this.parameter_tabledata,
        index,
        this.parameter_tabledata[index]
      )
      this.$emit('input', this.parameter_tabledata)
    },
    inputwetpadHandler(value, index, key, datatype, enable) {
      let tmpvalue = 0
      if (key.indexOf('wetpad') !== -1) {
        if (datatype === 'time') {
          tmpvalue = parseInt(value, 10)
          if (tmpvalue < 0) {
            tmpvalue = 0
          }
          if (tmpvalue > 864000) {
            tmpvalue = 864000
          }
        }
        if (enable === false) {
          this.tabledata[index][`${key}_close`] = tmpvalue
        } else {
          this.tabledata[index][`${key}_open`] = tmpvalue
        }
      }
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    showdeletebutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }
      if (this.tabledata[index].targetvalue === 0) {
        return false
      }
      return true
    },
    showaddbutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }
      if (this.tabledata[index + 1] !== undefined) {
        if (
          parseFloat(this.tabledata[index + 1].targetvalue) ===
          parseFloat(this.tabledata[index].targetvalue) + 0.1
        ) {
          return false
        }
      }
      return true
    },
    addRowHandler(index) {
      const newRow = { ...this.tabledata[index] }
      newRow.targetvalue = parseFloat(this.tabledata[index].targetvalue) + 1
      newRow.agename = parseFloat(this.tabledata[index].agename) + 1
      newRow.isedit_age = true
      newRow.isedit_value = true
      this.tabledata.push(newRow)
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
    },
    removeRowHandler(index) {
      this.tabledata = this.tabledata.filter((item, i) => i !== index)
    },
    getTargetValue(value) {
      const vuethis = this
      const valueobj = {
        offsetvalue: value,
        value_start: vuethis.targetvalue_start,
        value_end: vuethis.targetvalue_end,
        age_start: vuethis.breedingAge_start,
        age_end: vuethis.breedingAge_end,
        age_current: vuethis.breedingAge_current,
      }
      try {
        return value
      } catch (error) {
        console.log(error)
        return value
      }
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = this.$route.params.casepn
        this.regionpn = this.$route.params.regionkey
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
        // setTimeout(vuethis.getCaseSMCDeviceList(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        const vuethis = this
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_cooling',
            this.socketevent_casecloud_getcaseregion_cooling
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesregion_cooling')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_cooling(params) {
      try {
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'devicecontrolsetting') {
              return
            }
          }
        }
        if (params === undefined || params.type === 'error') {
          return
        }
        if (
          params.callback_params !==
          `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`
        ) {
          return
        }
        if (params.msg !== undefined) {
          if (params.msg.data !== undefined) {
            if (params.msg.data.data !== undefined) {
              this.tabledata = params.msg.data.data
              this.tabledata.sort((a, b) => {
                const a_targetvalue = parseFloat(a.targetvalue)
                const b_targetvalue = parseFloat(b.targetvalue)
                if (a_targetvalue < b_targetvalue) {
                  return -1
                }
                if (a_targetvalue > b_targetvalue) {
                  return 1
                }
                return 0
              })
              vuethis.temp_tabledata = Object.assign(
                [],
                vuethis.temp_tabledata,
                params.msg.data.temp_data
              )
            }
            if (params.msg.data.parameter_data !== undefined) {
              vuethis.parameter_tabledata = Object.assign(
                [],
                vuethis.parameter_tabledata,
                params.msg.data.parameter_data
              )
            }
          }
        }
        if (this.tabledata === undefined || this.tabledata === null) {
          this.tabledata = []
        }
        if (this.tabledata.length === 0) {
          this.tabledata = [
            {
              age: 0,
              targetvalue: 0,
              agename: '0',
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
            {
              age: 3650000,
              targetvalue: 3650000,
              agename: this.$t('common.infinitas'),
              isedit_age: false,
              isedit_value: false,
              ishide: true,
            },
          ]
        }

        if (
          this.parameter_tabledata === undefined ||
          this.parameter_tabledata === null
        ) {
          vuethis.parameter_tabledata = [
            {
              name: 'p_value',
              value: 3,
            },
            {
              name: 'min_time',
              value: 10,
            },
            {
              name: 'max_time',
              value: 60,
            },
            {
              name: 'operationtime',
              till: '00:00',
              from: '00:00',
            },
            {
              name: 'repeat_time',
              value: 480,
            },
            {
              name: 'humiditylimit',
              value: 95,
            },
          ]
        }
        if (this.temp_tabledata === undefined || this.temp_tabledata === null) {
          this.temp_tabledata = []
        }
        if (this.temp_tabledata.length === 0) {
          this.temp_tabledata = [
            {
              age: 0,
              targetvalue: 0,
              agename: '0',
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
          ]
        }
      } catch (error) {
        console.log(error)
      }
    },
    getData() {
      try {
        const vuethis = this
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          callback_params: `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`,
        }
        this.$socket.emit('casecloud_getcasesregion_cooling', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss"></style>
