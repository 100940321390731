<template>
  <div>
    <template v-if="tmploadedData === false">
      <b-row
        class="match-height d-flex flex-column align-items-center justify-content-center"
        style="height: calc(50vh)"
      >
        <b-col
          class="d-flex flex-column align-items-center justify-content-center p-3"
        >
          <h3>{{ $t(`common.loading`) }}</h3>
          <div class="demo-inline-spacing">
            <b-spinner class="mr-1" variant="info" />
          </div>
        </b-col>
      </b-row>
    </template>
    <transition
      v-if="tmploadedData === true"
      name="fade"
      class="w-100 d-flex row justify-content-between px-1"
      style="margin: 0px"
    >
      <div>
        <b-row>
          <transition name="fade">
            <b-col lg="12">
              <h1>{{ case_name }}</h1>
            </b-col>
          </transition>
        </b-row>
        <b-row>
          <transition name="fade">
            <b-col lg="12">
              <div class="d-flex align-items-center justify-content-start">
                <b-avatar size="38" rounded style="background-color: #0000">
                  <font-awesome-icon
                    style="font-size: 20px; margin-bottom: 0.5rem"
                    icon="fa-solid fa-warehouse"
                  />
                </b-avatar>
                <h2 style="margin-left: 0.5rem; letter-spacing: 3px">
                  {{ region_name }}
                </h2>
              </div>
            </b-col>
          </transition>
        </b-row>
        <b-row>
          <b-col style="padding: 0px">
            <b-tabs>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-house-chimney-window fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t(
                        'pages.devicecontrolsetting_buildingparameters.pagetitle'
                      )
                    }}</span
                  >
                </template>
                <CaseRegionSettingBuildparameter
                  v-if="
                    case_obj.case_region !== undefined && regionpn !== undefined
                  "
                  :regiontype="case_obj.case_region[regionpn].type"
                  :regiondevices="regiondevicesData['growthcurve']"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-fan fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t('pages.devicecontrolsetting_growthcurve.pagetitle')
                    }}</span
                  >
                </template>
                <CaseRegionSettingGrowthcurve
                  v-if="
                    case_obj.case_region !== undefined && regionpn !== undefined
                  "
                  :regiontype="case_obj.case_region[regionpn].type"
                  :regiondevices="regiondevicesData['growthcurve']"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-fan fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t('pages.devicecontrolsetting_ventilation.pagetitle')
                    }}</span
                  >
                </template>
                <CaseRegionSettingVentilation
                  :regiondevices="regiondevicesData['ventilation']"
                  :thermometers="regiondevicesData['thermometer']"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-temperature-arrow-down fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t('pages.devicecontrolsetting_cooling.pagetitle')
                    }}</span
                  >
                </template>
                <CaseRegionSettingCooling
                  :regiondevices="regiondevicesData['cooling']"
                  :thermometers="regiondevicesData['thermometer']"
              /></b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-temperature-arrow-up fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t('pages.devicecontrolsetting_heater.pagetitle')
                    }}</span
                  >
                </template>
                <CaseRegionSettingHeater
                  :regiondevices="regiondevicesData['heater']"
                  :thermometers="regiondevicesData['thermometer']"
              /></b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-lightbulb fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t('pages.devicecontrolsetting_lighting.pagetitle')
                    }}</span
                  >
                </template>
                <CaseRegionSettingLighting
                  :regiondevices="regiondevicesData['lighting']"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  <font-awesome-icon
                    style="font-size: 20px"
                    icon="fa-solid fa-bell fa-lg"
                  />
                  <span
                    class="d-flex align-items-center justify-content-center"
                    style="height: 40px"
                    >{{
                      $t('pages.devicecontrolsetting_alarm.pagetitle')
                    }}</span
                  >
                </template>
                <CaseRegionSettingAlarm
                  :regiondevices="regiondevicesData['growthcurve']"
                  :thermometers="regiondevicesData['thermometer']"
                  :smcs="regiondevicesData['smc']"
                  :hygrometers="regiondevicesData['hygrometer']"
                  :negativepressures="regiondevicesData['negativepressure']"
                  :carbondioxides="regiondevicesData['carbondioxide']"
                  :inverters="regiondevicesData['inverter']"
                  :alarms="regiondevicesData['alarm']"
                />
              </b-tab>

              <!-- <b-tab>
              <template #title>
                <font-awesome-icon
                      style="font-size: 20px"
                      icon="fa-solid fa-utensils fa-lg"
                    />
                <span>{{
                  $t('pages.devicecontrolsetting_feeding.pagetitle')
                }}</span>
              </template>
              <CaseRegionSettingFeeding
                :regiondevices="regiondevicesData['feeding']"
            /></b-tab> -->
            </b-tabs>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BAvatar,
  BSpinner,
  // BCard,
  VBTooltip,
  // BCardBody,
  // BTable,
  // BFormCheckbox,
  // BBadge,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

import CaseRegionSettingBuildparameter from './CaseRegionSetting_buildingparameters.vue'
import CaseRegionSettingGrowthcurve from './CaseRegionSetting_growthcurve.vue'
import CaseRegionSettingVentilation from './CaseRegionSetting_ventilation.vue'
import CaseRegionSettingLighting from './CaseRegionSetting_lighting.vue'
import CaseRegionSettingHeater from './CaseRegionSetting_heater.vue'
import CaseRegionSettingFeeding from './CaseRegionSetting_feeding.vue'
import CaseRegionSettingCooling from './CaseRegionSetting_cooling.vue'
import CaseRegionSettingAlarm from './CaseRegionSetting_alarm.vue'
// import CaseDevicesDataFan from './CaseDevicesData_Fan.vue'

export default {
  components: {
    // BCard,
    BAvatar,
    BTabs,
    BTab,
    BRow,
    BCol,
    BSpinner,
    // BFormCheckbox,
    // BBadge,
    // BButton,
    // BTable,
    CaseRegionSettingBuildparameter,
    CaseRegionSettingGrowthcurve,
    CaseRegionSettingVentilation,
    CaseRegionSettingCooling,
    CaseRegionSettingHeater,
    CaseRegionSettingLighting,
    CaseRegionSettingAlarm,
    // CaseRegionSettingFeeding,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {},
  data() {
    return {
      pagename: 'devicecontrolsetting',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      loadedData: false,
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      case_name: '',
      case_type: '',
      case_obj: {},
      residenceTime: {
        lastdatatime: 0,
        historydatatime: 0,
      },
      interval_devicecloud_getdevice: 0,
      deviceData: {},
      getdatafrequencyTime: {
        lastdatatime: 30000,
        historydatatime: 5 * 60000,
      },
      regiondevicesHistoryData: {},
    }
  },
  computed: {
    tmploadedData: {
      get() {
        return this.loadedData
      },
      set(val) {
        this.$emit('update:loadedData', val)
      },
    },
    region_name: {
      get() {
        if (
          this.regionpn !== '' &&
          this.case_obj.case_region !== undefined &&
          this.case_obj.case_region !== null &&
          this.case_obj.case_region[this.regionpn] !== undefined
        ) {
          return this.case_obj.case_region[this.regionpn].name
        }
        return ''
      },
      set(val) {
        this.$emit('update:regiondeviceslist', val)
      },
    },
    tmpregiondeviceslist: {
      get() {
        return this.regiondeviceslist
      },
      set(val) {
        this.$emit('update:regiondeviceslist', val)
      },
    },
    regiondevicesData() {
      try {
        const vuethis = this
        const tmpDeviceObj = this.getRegionDeviceTypeArray(
          vuethis.tmpregiondeviceslist.devices
        )
        vuethis.$forceUpdate()
        return tmpDeviceObj
      } catch (error) {
        console.log(error)
        return {}
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getRegionDeviceTypeArray(params) {
      try {
        const obj = {
          growthcurve: [],
          ventilation: [],
          heater: [],
          cooling: [],
          lighting: [],
          feeding: [],
          smc: [],
          alarm: [],
          thermometer: [],
          hygrometer: [],
          negativepressure: [],
          carbondioxide: [],
          inverter: [],
          devices: [],
        }
        if (params === undefined) {
          return obj
        }
        const sortArray_ventilation = [
          'inverter',
          'inverterfan',
          'fan',
          'diversionfan',
          'inlet',
          'tunnel',
        ]
        const sortArray_heater = ['heatlamp', 'heater', 'tempcontrolmeter']
        const sortArray_cooling = ['wetpad']
        const sortArray_lighting = ['lighting']
        const sortArray_feeding = ['feedbucket']
        const sortArray_smc = ['smc']
        const sortArray_alarm = ['alarm']
        const sortArray_thermometer = ['thermometer', 'thermometer_outside']
        const sortArray_hygrometer = ['hygrometer', 'carbondioxide_outside']
        const sortArray_negativepressure = ['negativepressure']
        const sortArray_carbondioxide = [
          'carbondioxide',
          'carbondioxide_outside',
        ]
        const sortArray_inverter = ['inverter', 'inverterfan']

        Object.keys(params).forEach(regiondevicekey => {
          if (
            sortArray_ventilation.indexOf(
              params[regiondevicekey].devicetype
            ) !== -1
          ) {
            obj.ventilation.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_heater.indexOf(params[regiondevicekey].devicetype) !== -1
          ) {
            obj.heater.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_cooling.indexOf(params[regiondevicekey].devicetype) !== -1
          ) {
            obj.cooling.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_lighting.indexOf(params[regiondevicekey].devicetype) !==
            -1
          ) {
            obj.lighting.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_feeding.indexOf(params[regiondevicekey].devicetype) !== -1
          ) {
            obj.feeding.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_smc.indexOf(params[regiondevicekey].devicetype) !== -1
          ) {
            obj.smc.push(params[regiondevicekey])
            obj.growthcurve.push(params[regiondevicekey])
          }
          if (
            sortArray_alarm.indexOf(params[regiondevicekey].devicetype) !== -1
          ) {
            obj.alarm.push(params[regiondevicekey])
          }
          if (
            sortArray_thermometer.indexOf(
              params[regiondevicekey].devicetype
            ) !== -1
          ) {
            obj.thermometer.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_hygrometer.indexOf(params[regiondevicekey].devicetype) !==
            -1
          ) {
            obj.hygrometer.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_negativepressure.indexOf(
              params[regiondevicekey].devicetype
            ) !== -1
          ) {
            obj.negativepressure.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_carbondioxide.indexOf(
              params[regiondevicekey].devicetype
            ) !== -1
          ) {
            obj.carbondioxide.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }
          if (
            sortArray_inverter.indexOf(params[regiondevicekey].devicetype) !==
            -1
          ) {
            obj.inverter.push({
              regiondevicekey,
              info: params[regiondevicekey],
            })
          }

          obj.devices.push({
            regiondevicekey,
            info: params[regiondevicekey],
          })
        })

        obj.ventilation.sort(
          (a, b) =>
            sortArray_ventilation.indexOf(a) - sortArray_ventilation.indexOf(b)
        )
        obj.heater.sort(
          (a, b) => sortArray_heater.indexOf(a) - sortArray_heater.indexOf(b)
        )
        obj.cooling.sort(
          (a, b) => sortArray_cooling.indexOf(a) - sortArray_cooling.indexOf(b)
        )
        obj.lighting.sort(
          (a, b) =>
            sortArray_lighting.indexOf(a) - sortArray_lighting.indexOf(b)
        )
        obj.feeding.sort(
          (a, b) => sortArray_feeding.indexOf(a) - sortArray_feeding.indexOf(b)
        )
        obj.alarm.sort(
          (a, b) => sortArray_alarm.indexOf(a) - sortArray_alarm.indexOf(b)
        )
        obj.thermometer.sort(
          (a, b) =>
            sortArray_thermometer.indexOf(a) - sortArray_thermometer.indexOf(b)
        )
        obj.hygrometer.sort(
          (a, b) =>
            sortArray_hygrometer.indexOf(a) - sortArray_hygrometer.indexOf(b)
        )
        obj.negativepressure.sort(
          (a, b) =>
            sortArray_negativepressure.indexOf(a) -
            sortArray_negativepressure.indexOf(b)
        )
        obj.carbondioxide.sort(
          (a, b) =>
            sortArray_carbondioxide.indexOf(a) -
            sortArray_carbondioxide.indexOf(b)
        )
        obj.inverter.sort(
          (a, b) =>
            sortArray_inverter.indexOf(a) - sortArray_inverter.indexOf(b)
        )

        return obj
      } catch (error) {
        console.log(error)
        return []
      }
    },
    getCaseSMCDeviceList() {
      try {
        const vuethis = this
        const queryParams = {
          pn: this.casepn,
          regionpn: this.regionpn,
          callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
        }
        if (
          this.casepn === undefined ||
          this.casepn === '' ||
          this.casepn == null
        ) {
          return
        }
        if (
          this.regionpn === undefined ||
          this.regionpn === '' ||
          this.regionpn == null
        ) {
          return
        }
        this.$socket.emit('casecloud_getcasesmcdevice', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
    getCasesInfo() {
      try {
        const vuethis = this
        const queryParams = {
          pn: this.casepn,
          callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
        }
        this.$socket.emit('casecloud_getcaseinfo', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
    getCaseDeviceDate() {
      try {
        const vuethis = this
        this.getHistoryData(this.regiondevicesData.thermometer, true)
      } catch (error) {
        console.log(error)
      }
    },
    toggle(data, key) {
      try {
        if (
          data.item[key] === undefined ||
          data.item[key] === null ||
          data.item[key] === ''
        ) {
          data.item[key] = 1
          this.$refs.getFanTable.refresh()
          return
        }
        if (data.item[key] === 1) {
          data.item[key] = 0
        } else if (data.value === 0) {
          data.item[key] = 1
        }
        this.$refs.getFanTable.refresh()
        return
      } catch (error) {
        console.log(error)
      }
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = this.$route.params.casepn
        this.regionpn = this.$route.params.regionkey
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
        setTimeout(vuethis.getCaseSMCDeviceList(), 100)
        setTimeout(vuethis.getCasesInfo(), 100)
        setTimeout(vuethis.getCaseDeviceDate(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesmcdevice',
            this.socketevent_casecloud_getcasesmcdevice
          )
          this.sockets.subscribe(
            'reply_devicecloud_getdevicehistorydata',
            this.socketevent_reply_devicecloud_getdevicehistorydata
          )
          this.sockets.subscribe(
            'reply_devicecloud_getdevicelatestrawdata',
            this.socketevent_reply_devicecloud_getdevicelatestrawdata
          )
          this.sockets.subscribe(
            'reply_casecloud_getcaseinfo',
            this.socketevent_reply_casecloud_getcaseinfo
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesmcdevice')
          this.sockets.unsubscribe('reply_casecloud_getcaseinfo')
          this.sockets.unsubscribe('reply_devicecloud_getdevicehistorydata')
          this.sockets.unsubscribe('reply_devicecloud_getdevicelatestrawdata')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcasesmcdevice(params) {
      try {
        const vuethis = this
        if (
          vuethis.$router !== undefined &&
          vuethis.$router.history !== undefined &&
          vuethis.$router.history.current
        ) {
          if (vuethis.$router.history.current.name !== undefined) {
            if (
              vuethis.$router.history.current.name !== 'devicecontrolsetting'
            ) {
              return
            }
          }
        }
        if (params.type === 'error') {
          vuethis
            .$swal({
              icon: 'error',
              title: vuethis.$t('error_common.notfound_data'),
              showConfirmButton: true,
              timer: 2000,
              buttonsStyling: true,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            .then(result => {
              vuethis.$router
                .push({
                  name: 'casestatus',
                  params: { casepn: vuethis.casepn },
                })
                .catch(error => {
                  // console.warn(error.message)
                })
            })
          return
        }
        if (params.msg === undefined) {
          return
        }
        this.regiondeviceslist = params.msg
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_casecloud_getcaseinfo(params) {
      try {
        const vuethis = this
        if (params === undefined) {
          vuethis.error_loading = true
        }
        if (
          params.type === undefined ||
          params.type === 'error' ||
          params.msg === undefined
        ) {
          vuethis.error_loading = true
        }
        if (vuethis.error_loading === true) {
          let errormsg = ''
          if (params.msg === 'user is undefined') {
            const userData = Tientech_getCookie('userData')
            if (
              userData !== undefined &&
              userData !== null &&
              userData !== ''
            ) {
              window.location.reload()
              return
            }
          }
          if (params.msg === 'database_disconnect') {
            errormsg = this.$t('error_common.database_disconnect')
          } else {
            errormsg = this.$t('error_common.insufficient_permissions')
          }

          this.$swal({
            icon: 'error',
            title: errormsg,
            showConfirmButton: false,
            timer: 1000,
            buttonsStyling: false,
          }).then(result => {
            // this.$router.push({ name: 'dashboard' })
          })
          return
        }
        vuethis.loadedData = true
        vuethis.total_cases = 0
        vuethis.caselistObject = {}
        vuethis.caselistArray = []

        vuethis.total_cases = Object.keys(params.msg).length
        const tmpcases = params.msg
        Object.keys(tmpcases).forEach(pn => {
          try {
            let obj = {
              sys: {
                pn,
              },
            }
            obj = Object.assign([], obj, tmpcases[pn])
            obj = Tientech_CaseInit(obj)

            if (vuethis.caselistObject[pn] === undefined) {
              vuethis.caselistArray.push(obj)
              vuethis.caselistObject[pn] = {
                index: vuethis.caselistArray.length - 1,
              }
            } else {
              vuethis.caselistArray[vuethis.caselistObject[pn].index] = obj
            }
            vuethis.case_name = obj.case_info.name
            vuethis.case_obj = obj
            // console.log(obj)
            vuethis.$forceUpdate()
          } catch (error) {
            console.log(error)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_devicecloud_getdevicehistorydata(params) {
      try {
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'casestatus') {
              return
            }
          }
        }

        if (params === undefined || params.type === 'error') {
          return
        }
        if (params.msg === undefined) {
          return
        }
        const tmpdeviceHistoryData = { ...this.deviceHistoryData }
        Object.keys(params.msg).forEach(smcpn => {
          Object.keys(params.msg[smcpn]).forEach(devicepn => {
            if (tmpdeviceHistoryData[smcpn] === undefined) {
              tmpdeviceHistoryData[smcpn] = {}
            }
            if (tmpdeviceHistoryData[smcpn][devicepn] === undefined) {
              tmpdeviceHistoryData[smcpn][devicepn] = {}
            }
            if (tmpdeviceHistoryData[smcpn][devicepn].history === undefined) {
              tmpdeviceHistoryData[smcpn][devicepn].history = {}
            }
            if (
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata ===
              undefined
            ) {
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata = {}
            }
            tmpdeviceHistoryData[smcpn][devicepn].history.detaildata =
              Object.assign(
                [],
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata,
                params.msg[smcpn][devicepn]
              )
            Object.keys(
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata
            ).forEach(dateindex => {
              const tmpdevicedata =
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata[
                  dateindex
                ]
              let targetvalue = null
              let targetunit = ''
              const targetdatetime = tmpdevicedata.datetime
              const targetname = tmpdevicedata.name
              if (
                tmpdevicedata.data !== undefined &&
                tmpdevicedata.replace !== undefined &&
                tmpdevicedata.replace !== ''
              ) {
                targetvalue = tmpdevicedata.data[tmpdevicedata.replace]
                targetunit =
                  tmpdevicedata.replace.split('#').length > 1
                    ? tmpdevicedata.replace.split('#')[1]
                    : ''
              }
              if (
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata ===
                undefined
              ) {
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata = {}
              }
              if (
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                  targetdatetime
                ] === undefined
              ) {
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                  targetdatetime
                ] = {}
              }
              tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                targetdatetime
              ] = {
                datetime: targetdatetime,
                value: targetvalue,
                unit: targetunit,
                name: targetname,
              }
            })
          })
          if (this.autogethistory === undefined) {
            this.autogethistory = {}
          }
          if (this.autogethistory[smcpn] === undefined) {
            this.autogethistory[smcpn] = {}
          }
          this.autogethistory[smcpn] = new Date().getTime()
          this.autogethistory = { ...this.autogethistory }
        })
        this.deviceHistoryData = { ...tmpdeviceHistoryData }
        this.getChartOption()
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_devicecloud_getdevicelatestrawdata(params) {
      try {
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'devicecontrolsetting') {
              return
            }
          }
        }

        if (params === undefined || params.type === 'error') {
          this.isloading = false
          return
        }
        if (params.msg === undefined) {
          this.isloading = false
          return
        }
        const tmpdeviceData = { ...vuethis.deviceData }
        Object.keys(params.msg).forEach(smcpn => {
          Object.keys(params.msg[smcpn]).forEach(devicepn => {
            try {
              if (tmpdeviceData[smcpn] === undefined) {
                tmpdeviceData[smcpn] = {}
              }
              if (tmpdeviceData[smcpn].devices === undefined) {
                tmpdeviceData[smcpn].devices = {}
              }
              if (tmpdeviceData[smcpn].devices[devicepn] === undefined) {
                tmpdeviceData[smcpn].devices[devicepn] = {}
              }
              tmpdeviceData[smcpn].devices[devicepn].latest_rawdata =
                params.msg[smcpn][devicepn]
            } catch (error) {
              console.log(error)
            }
          })
        })
        vuethis.deviceData = { ...tmpdeviceData }
        vuethis.$forceUpdate()
        vuethis.isloading = false
      } catch (error) {
        console.log(error)
      }
    },
    getHistoryData(regiondevice, getlatestrawdata) {
      try {
        const vuethis = this
        if (regiondevice === undefined) {
          return
        }
        const obj = {}
        const smcdevicelist = []
        regiondevice.forEach(deviceitem => {
          const smcpn = deviceitem.smcpn
          const devicepn = deviceitem.devicepn
          if (smcdevicelist.indexOf(smcpn) === -1) {
            smcdevicelist.push(smcpn)
          }
          if (obj[smcpn] === undefined) {
            obj[smcpn] = {}
          }
          if (obj[smcpn][devicepn] === undefined) {
            obj[smcpn][devicepn] = { devicepn }
          }
          if (vuethis.deviceData === undefined) {
            vuethis.deviceData = {}
          }
          if (vuethis.deviceData[smcpn] === undefined) {
            vuethis.deviceData[smcpn] = {}
            vuethis.deviceData[smcpn].devices = {}
          }
          if (vuethis.deviceData[smcpn].devices[devicepn] === undefined) {
            vuethis.deviceData[smcpn].devices[devicepn] = {}
          }
          if (vuethis.deviceHistoryData === undefined) {
            vuethis.deviceHistoryData = {}
          }
          if (vuethis.deviceHistoryData[smcpn] === undefined) {
            vuethis.deviceHistoryData[smcpn] = {}
          }
          if (vuethis.autogethistory === undefined) {
            vuethis.autogethistory = {}
          }
          if (vuethis.autogethistory[smcpn] === undefined) {
            vuethis.autogethistory[smcpn] = 0
          }
        })
        const datatime = new Date()
        const data_year = datatime.getFullYear()
        const data_month = `0${datatime.getMonth() + 1}`.substr(-2)
        const data_date = `0${datatime.getDate()}`.substr(-2)
        const data_hour = `0${datatime.getHours()}`.substr(-2)
        const data_min = `0${datatime.getMinutes()}`.substr(-2)
        const date_daily = [data_year, data_month, data_date].join('/')
        const tmpdatetime = new Date(`${date_daily} 00:00:00`).getTime()
        this.isloading = true
        if (getlatestrawdata === false) {
          Object.keys(obj).forEach(smcpn => {
            if (
              vuethis.autogethistory[smcpn] !== undefined &&
              vuethis.autogethistory[smcpn] !== 0
            ) {
              const nowDate = new Date().getTime()
              if (nowDate - vuethis.autogethistory[smcpn] >= 10 * 60 * 1000) {
                vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                  datetime: tmpdatetime,
                  data: obj,
                  devicelist: smcdevicelist,
                  callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
                })
              }
            } else {
              // console.log(smcdevicelist)
              vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                data: obj,
                devicelist: smcdevicelist,
                callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
              })
            }
          })
        } else {
          vuethis.$socket.emit('devicecloud_getdevicelatestrawdata', {
            data: obj,
            devicelist: smcdevicelist,
            callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style lang="scss">
// .echarts {
//   width: 100%;
//   height: 180px;
// }
// .slow-spin {
//   -webkit-animation: fa-spin 5s infinite linear;
//   animation: fa-spin 5s infinite linear;
// }
// .middle-slow-spin {
//   -webkit-animation: fa-spin 3s infinite linear;
//   animation: fa-spin 3s infinite linear;
// }
// .middle-spin {
//   -webkit-animation: fa-spin 1.5s infinite linear;
//   animation: fa-spin 1.5s infinite linear;
// }
// .fast-spin {
//   -webkit-animation: fa-spin 1s infinite linear;
//   animation: fa-spin 1s infinite linear;
// }
</style>
