<template>
  <transition
    name="fade"
    class="w-100 d-flex row justify-content-between px-1"
    style="margin: 0px"
  >
    <div>
      <b-col>
        <!-- <h2>{{ $t(`pages.${pagename}.pagetitle`) }}</h2> -->
        <div
          v-if="true"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-table
            ref="getParameterTable"
            :items="parameter_tabledata"
            :fields="parameter_fields"
            :sort-desc="false"
            sort-icon-right
            striped
            :fixed="false"
            responsive
            class="mb-0"
          >
            <template #head()="scope">
              <div class="text-nowrap">
                {{ $t(`common.${scope.field.key}`) }}
              </div>
            </template>
            <template #cell()="data">
              <template v-if="data.field.key === 'name'">
                <!-- name -->
                <div
                  class="text-nowrap"
                  style="font-weight: 600; font-size: 1.2rem"
                >
                  {{ $t(`common.${data.value}`) }}
                </div>
              </template>
              <template v-else>
                <template v-if="data.item.name === 'feedingdate'">
                  <div class="">
                    <div
                      class="d-flex flex-row align-items-center justify-content-end"
                    >
                      <b-form-datepicker
                        v-model="data.item.value"
                        locale="zh"
                        style="width: 190px"
                        placeholder="YYYY-MM-DD"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        @input="
                          timepickerinputHandler(
                            $event,
                            data.index,
                            data.field.key,
                            1
                          )
                        "
                      />
                    </div>
                    <!-- @blur="
                          timepickerinputHandler(
                            $event,
                            data.index,
                            data.field.key,
                            1
                          )
                        " -->
                  </div>
                </template>
                <template v-else-if="data.item.name === 'feedingage'">
                  <div class="">
                    <div
                      class="d-flex flex-row align-items-center justify-content-end"
                    >
                      <b-form-input
                        v-model="data.value"
                        type="number"
                        style="width: 100px; text-align: center"
                        @blur="
                          parameterinputHandler(
                            $event,
                            data.index,
                            data.item.name,
                            1
                          )
                        "
                      />
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="d-flex flex-row align-items-center justify-content-end"
                  >
                    <b-form-input
                      v-model="data.value"
                      type="number"
                      style="width: 100px; text-align: center"
                      @blur="
                        parameterinputHandler(
                          $event,
                          data.index,
                          data.item.name,
                          1
                        )
                      "
                    />
                  </div>
                </template>
              </template>
            </template>
          </b-table>
          <b-button
            variant="success"
            style="margin: 10px 0px"
            @click="savetabledata()"
          >
            {{ $t(`common.save`) }}
          </b-button>
        </div>
      </b-col>
    </div>
  </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  VBTooltip,
  BCardBody,
  BTable,
  BFormCheckbox,
  BBadge,
  BButton,
  BFormInput,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

export default {
  components: {
    // BCard,
    BAvatar,
    // BRow,
    BCol,
    // BFormCheckbox,
    // BBadge,
    BButton,
    BFormInput,
    BTable,
    BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondevices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagename: 'devicecontrolsetting_cooling',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      targetvalue_start: 25,
      targetvalue_end: 24,
      breedingAge_start: 0,
      breedingAge_end: 35,
      breedingAge_current: 0,
      tabledata: [],
      parameter_tabledata: [],
    }
  },
  computed: {
    fields() {
      const tmpArray = []
      try {
        tmpArray.push({
          key: 'index',
          label: '#',
          type: 'number',
          thStyle: { 'max-width': '30px' },
        })
        tmpArray.push({
          key: 'targetvalue',
          label: this.$t('common.temperature') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        const tmpregiondevicesArray = this.regiondevices

        tmpregiondevicesArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          if (a_name < b_name) {
            return -1
          }
          if (a_name > b_name) {
            return 1
          }
          return 0
        })
        if (tmpregiondevicesArray !== undefined) {
          tmpregiondevicesArray.forEach(element => {
            if (element.info.devicetype === 'wetpad') {
              tmpArray.push({
                key: `${element.info.devicetype}_${element.regiondevicekey}`,
                label: element.info.name,
                type: element.info.devicetype,
                thStyle: {
                  'min-width': '105px',
                  'padding-right': '1rem',
                  'text-align': 'center',
                },
              })
            }
          })
        }

        tmpArray.push({ key: 'add', label: '', width: '84px' })
        tmpArray.push({ key: 'delete', label: '' })
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
    parameter_fields() {
      const tmpArray = []
      try {
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    savetabledata() {
      try {
        this.parameter_tabledata.forEach((element1, index1) => {
          if (element1.name === 'feedingage') {
            element1.value = Number.parseInt(element1.value, 10)
            if (Number.isNaN(element1.value) === true) {
              element1.value = 0
            }
            this.parameter_tabledata.forEach((element2, index2) => {
              if (element2.name === 'feedingdate') {
                if (element1.value !== undefined) {
                  const date = new Date()
                  const newdate = new Date(
                    date.setDate(date.getDate() - element1.value)
                  )
                  element2.value = `${newdate.getFullYear()}-${
                    newdate.getMonth() + 1
                  }-${newdate.getDate()}`
                }
              }
            })
          }
        })
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          data: this.tabledata,
          parameter_data: this.parameter_tabledata,
        }
        this.$socket.emit('casecloud_setcasesregion_config', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    inputHandler(value, index, key, datatype, enable) {
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    timepickerinputHandler(event, index, key, datatype, enable) {
      const value = event
      this.parameter_tabledata[index][key] = value
      this.parameter_tabledata.forEach((element1, index1) => {
        if (element1.name === 'feedingage') {
          const date = new Date().getTime()
          const feedingdate = new Date(value).getTime()
          const day = Math.ceil((date - feedingdate) / 86400000) - 1
          element1.value = day
        }
      })
      this.$set(
        this.parameter_tabledata,
        index,
        this.parameter_tabledata[index]
      )
      this.$emit('input', this.parameter_tabledata)
    },
    parameterinputHandler(event, index, key, datatype, enable) {
      const value = Number.parseInt(event.target.value, 10)
      this.parameter_tabledata[index].value = value
      if (key === 'feedingage') {
        this.parameter_tabledata.forEach((element2, index2) => {
          if (element2.name === 'feedingdate') {
            if (value !== undefined) {
              const date = new Date()
              const newdate = new Date(date.setDate(date.getDate() - value))
              element2.value = `${newdate.getFullYear()}-${
                newdate.getMonth() + 1
              }-${newdate.getDate()}`
            }
          }
        })
      }
      this.$set(
        this.parameter_tabledata,
        index,
        this.parameter_tabledata[index]
      )
      this.$emit('input', this.parameter_tabledata)
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = this.$route.params.casepn
        this.regionpn = this.$route.params.regionkey
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
        // setTimeout(vuethis.getCaseSMCDeviceList(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        const vuethis = this
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_config',
            this.socketevent_casecloud_getcaseregion_config
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesregion_config')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_config(params) {
      try {
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'devicecontrolsetting') {
              return
            }
          }
        }
        if (params === undefined || params.type === 'error') {
          return
        }
        if (params.msg !== undefined) {
          if (params.msg.data !== undefined) {
            if (params.msg.data.data !== undefined) {
              this.tabledata = params.msg.data.data
              this.tabledata.sort((a, b) => {
                const a_targetvalue = parseFloat(a.targetvalue)
                const b_targetvalue = parseFloat(b.targetvalue)
                if (a_targetvalue < b_targetvalue) {
                  return -1
                }
                if (a_targetvalue > b_targetvalue) {
                  return 1
                }
                return 0
              })
            }
            if (params.msg.data.parameter_data !== undefined) {
              vuethis.parameter_tabledata = Object.assign(
                [],
                vuethis.parameter_tabledata,
                params.msg.data.parameter_data
              )
            }
          }
        }
        if (this.tabledata === undefined || this.tabledata === null) {
          this.tabledata = []
        }
        if (this.tabledata.length === 0) {
          this.tabledata = [
            {
              age: 0,
              targetvalue: 0,
              agename: '0',
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
            {
              age: 3650000,
              targetvalue: 3650000,
              agename: this.$t('common.infinitas'),
              isedit_age: false,
              isedit_value: false,
              ishide: true,
            },
          ]
        }

        const default_parameter = {
          feedingdate: 0,
          feedingage: 0,
          feedingquantity: 0,
          house_length: 0,
          house_height: 0,
          house_width: 0,
          house_sectionalarea: 0,
        }
        if (
          this.parameter_tabledata === undefined ||
          this.parameter_tabledata === null ||
          this.parameter_tabledata.length !==
            Object.keys(default_parameter).length
        ) {
          Object.keys(default_parameter).forEach(item1 => {
            const isfind = vuethis.parameter_tabledata.find(element => {
              if (element.name === item1) {
                return true
              }
              return false
            })
            if (isfind === undefined) {
              vuethis.parameter_tabledata.push({
                name: item1,
                value: default_parameter[item1],
              })
            }
          })
        }

        this.parameter_tabledata.forEach((element1, index1) => {
          if (element1.name === 'feedingdate') {
            const date = new Date().getTime()
            const feedingdate = new Date(element1.value).getTime()
            if (Number.isNaN(feedingdate) !== true) {
              const day = Math.ceil((date - feedingdate) / 86400000) - 1
              this.parameter_tabledata.forEach(element2 => {
                if (element2.name === 'feedingage') {
                  if (day !== undefined) {
                    element2.value = day
                  }
                }
              })
            }
          }
        })

        const tmpArray = vuethis.parameter_tabledata.sort((a, b) => {
          if (
            Object.keys(default_parameter).indexOf(a.name) <
            Object.keys(default_parameter).indexOf(b.name)
          ) {
            return -1
          }
          if (
            Object.keys(default_parameter).indexOf(a.name) >
            Object.keys(default_parameter).indexOf(b.name)
          ) {
            return 1
          }
          return 0
        })

        // check parameter
      } catch (error) {
        console.log(error)
      }
    },
    getData() {
      try {
        const vuethis = this
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
        }
        this.$socket.emit('casecloud_getcasesregion_config', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss"></style>
