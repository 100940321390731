var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{staticClass:"w-100 d-flex row justify-content-between px-1",staticStyle:{"margin":"0px"},attrs:{"name":"fade"}},[_c('div',[_c('b-col',[(true)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('b-table',{ref:"getParameterTable",staticClass:"mb-0",attrs:{"items":_vm.parameter_tabledata,"fields":_vm.parameter_fields,"sort-desc":false,"sort-icon-right":"","striped":"","fixed":false,"responsive":""},scopedSlots:_vm._u([{key:"head()",fn:function(scope){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t(("common." + (scope.field.key))))+" ")])]}},{key:"cell()",fn:function(data){return [(data.field.key === 'name')?[_c('div',{staticClass:"text-nowrap",staticStyle:{"font-weight":"600","font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.$t(("common." + (data.value))))+" ")])]:[(data.item.name === 'feedingdate')?[_c('div',{},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"},[_c('b-form-datepicker',{staticStyle:{"width":"190px"},attrs:{"locale":"zh","placeholder":"YYYY-MM-DD","date-format-options":{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }},on:{"input":function($event){return _vm.timepickerinputHandler(
                          $event,
                          data.index,
                          data.field.key,
                          1
                        )}},model:{value:(data.item.value),callback:function ($$v) {_vm.$set(data.item, "value", $$v)},expression:"data.item.value"}})],1)])]:(data.item.name === 'feedingage')?[_c('div',{},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"},[_c('b-form-input',{staticStyle:{"width":"100px","text-align":"center"},attrs:{"type":"number"},on:{"blur":function($event){return _vm.parameterinputHandler(
                          $event,
                          data.index,
                          data.item.name,
                          1
                        )}},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}})],1)])]:[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"},[_c('b-form-input',{staticStyle:{"width":"100px","text-align":"center"},attrs:{"type":"number"},on:{"blur":function($event){return _vm.parameterinputHandler(
                        $event,
                        data.index,
                        data.item.name,
                        1
                      )}},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}})],1)]]]}}],null,false,4098244723)}),_c('b-button',{staticStyle:{"margin":"10px 0px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.savetabledata()}}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" ")])],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }