<template>
  <transition
    name="fade"
    class="w-100 d-flex row justify-content-between px-1"
    style="margin: 0px"
  >
    <div>
      <b-col>
        <h3>設備</h3>
        <div v-if="regiondevices.length === 0">無設備</div>
        <div v-if="regiondevices.length !== 0">
          <b-form-select
            v-model="devicesoptions_selected"
            :options="devicesoptions"
          />
        </div>

        <hr />
        <!-- <h2>{{ $t(`pages.${pagename}.pagetitle`) }}</h2> -->
        <div
          v-if="true"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-table
            ref="getTempTable"
            :items="temp_tabledata"
            :fields="temp_fields"
            :sort-desc="false"
            sort-icon-right
            striped
            :fixed="false"
            responsive
            class="mb-0"
          >
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>

            <!-- A virtual column -->
            <template #cell(index)="">
              <div class="text-center">
                {{ $t(`common.thermometer`) + $t(`common.select`) }}
              </div>
            </template>
            <template #cell()="data">
              <div
                class="d-flex flex-column justify-content-center align-items-center"
                @click="toggletemp(data.value, data.index, data.field.key)"
              >
                <template v-if="data.value === 1 || data.value === ''">
                  <b-avatar class="" variant="primary" size="40">
                    <div>
                      <font-awesome-icon
                        style="font-size: 20px"
                        icon="fa-solid fa-temperature-three-quarters fa-lg"
                        class=""
                      />
                    </div>
                  </b-avatar>
                </template>
                <template v-if="data.value === 0">
                  <b-avatar class="" variant="light-secondary" size="40">
                    <div>
                      <font-awesome-icon
                        style="font-size: 20px"
                        icon="fa-solid fa-temperature-three-quarters fa-lg"
                        class=""
                      />
                    </div>
                  </b-avatar>
                </template>
              </div>
            </template>
          </b-table>
        </div>
        <div
          v-if="true"
          class="d-flex flex-column align-items-center justify-content-center mt-2"
        >
          <b-table
            ref="getParameterTable"
            :items="parameter_tabledata"
            :fields="parameter_fields"
            :sort-desc="false"
            sort-icon-right
            striped
            :fixed="false"
            responsive
            class="mb-0"
          >
            <template #head()="scope">
              <div class="text-nowrap">
                {{ $t(`common.${scope.field.key}`) }}
              </div>
            </template>
            <template #cell()="data">
              <template v-if="data.field.key === 'name'">
                <!-- name -->
                <div
                  class="text-nowrap"
                  style="font-weight: 600; font-size: 1.2rem"
                >
                  {{ $t(`common.${data.value}`) }}
                </div>
              </template>
              <template v-else>
                <template v-if="data.item.name === 'operationtime'">
                  <div class="">
                    <div
                      class="d-flex flex-row align-items-center justify-content-between"
                    >
                      <div>FROM</div>
                      <b-form-timepicker
                        v-model="data.item.from"
                        locale="zh"
                        style="width: 120px"
                        placeholder="00:00"
                        @blur="
                          timepickerinputHandler(
                            $event,
                            data.index,
                            data.field.key,
                            1
                          )
                        "
                      />
                    </div>
                    <div
                      class="d-flex flex-row align-items-center justify-content-between"
                    >
                      <div>Till</div>
                      <b-form-timepicker
                        v-model="data.item.till"
                        locale="zh"
                        style="width: 120px"
                        placeholder="00:00"
                        @blur="
                          timepickerinputHandler(
                            $event,
                            data.index,
                            data.field.key,
                            1
                          )
                        "
                      />
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="d-flex flex-row align-items-center justify-content-end"
                  >
                    <b-form-input
                      v-model="data.value"
                      type="text"
                      style="width: 75px; text-align: center"
                      @blur="
                        parameterinputHandler(
                          $event,
                          data.index,
                          data.field.key,
                          1
                        )
                      "
                    />
                  </div>
                </template>
              </template>
            </template>
          </b-table>
          <b-button
            variant="success"
            style="margin: 10px 0px"
            @click="savetabledata()"
          >
            {{ $t(`common.save`) }}
          </b-button>
        </div>
      </b-col>
    </div>
  </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  VBTooltip,
  BCardBody,
  BTable,
  BFormCheckbox,
  BFormSelect,
  BBadge,
  BButton,
  BFormInput,
  BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

export default {
  components: {
    // BCard,
    BAvatar,
    // BRow,
    BCol,
    // BFormCheckbox,
    // BBadge,
    BFormSelect,
    BButton,
    BFormInput,
    BTable,
    BFormTimepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondevices: {
      type: Array,
      default: () => [],
    },
    thermometers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagename: 'devicecontrolsetting_heater',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      targetvalue_start: 25,
      targetvalue_end: 24,
      breedingAge_start: 0,
      breedingAge_end: 35,
      breedingAge_current: 0,
      tabledata: [],
      temp_tabledata: [],
      parameter_tabledata: [
        {
          name: 'hy_value',
          value: 0.4,
        },
        {
          name: 'min_time',
          value: 120,
        },
        {
          name: 'operationtime',
          from: '00:00',
          till: '00:00',
        },
      ],
      device_parameter_data: {},
      devicesoptions_selected: null,
    }
  },
  computed: {
    devicesoptions() {
      const options = []
      try {
        if (this.regiondevices.length === 0) {
          return options
        }

        this.regiondevices.forEach(element => {
          const obj = { value: '', text: '' }
          obj.value = element.regiondevicekey
          obj.text = element.info.name
          options.push(obj)
        })

        return options
      } catch (error) {
        console.log(error)
        return options
      }
    },
    fields() {
      const tmpArray = []
      try {
        tmpArray.push({
          key: 'index',
          label: '',
          type: 'number',
          thStyle: { 'max-width': '30px' },
        })
        tmpArray.push({
          key: 'targetvalue',
          label: this.$t('common.temperature') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        const tmpregiondevicesArray = this.regiondevices

        tmpregiondevicesArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          if (a_name < b_name) {
            return -1
          }
          if (a_name > b_name) {
            return 1
          }
          return 0
        })
        if (tmpregiondevicesArray !== undefined) {
          tmpregiondevicesArray.forEach(element => {
            if (element.info.devicetype === 'heatlamp') {
              tmpArray.push({
                key: `${element.info.devicetype}_${element.regiondevicekey}`,
                label: element.info.name,
                type: element.info.devicetype,
                thStyle: {
                  'min-width': '105px',
                  'padding-right': '1rem',
                  'text-align': 'center',
                },
              })
            }
          })
        }

        tmpArray.push({ key: 'add', label: '', width: '84px' })
        tmpArray.push({ key: 'delete', label: '' })
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
    parameter_fields() {
      const tmpArray = []
      try {
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
    temp_fields() {
      const tmpArray = []
      try {
        tmpArray.push({
          key: 'index',
          label: '#',
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        const tmpthermometersArray = this.thermometers

        tmpthermometersArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          if (a_name < b_name) {
            return -1
          }
          if (a_name > b_name) {
            return 1
          }
          return 0
        })
        tmpthermometersArray.forEach(element => {
          if (element.info.devicetype === 'thermometer') {
            tmpArray.push({
              key: `${element.info.devicetype}_${element.regiondevicekey}`,
              label: element.info.name,
              type: element.info.devicetype,
              thStyle: {
                'min-width': '105px',
                'padding-right': '1rem',
                'text-align': 'center',
              },
            })
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
      clearInterval(this.interval_devicecloud_getdevice)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      clearInterval(this.interval_devicecloud_getdevice)
      document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    toggletemp(value, index, key) {
      try {
        if (key.indexOf('thermometer') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 0
          }
          if (value === 0) {
            fanstatus = 1
          } else if (value === 1) {
            fanstatus = 0
          }
          this.temp_tabledata[index][key] = fanstatus
        }

        this.$set(this.temp_tabledata, index, this.temp_tabledata[index])
        this.$emit('input', this.temp_tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    toggleheatlamp(value, index, key) {
      try {
        if (key.indexOf('heatlamp') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 1
          }
          if (value === 0) {
            fanstatus = 1
          } else if (value === 1) {
            fanstatus = 2
          } else if (value === 2) {
            fanstatus = 0
          }
          this.tabledata[index][key] = fanstatus
        }
        this.tabledata.sort((a, b) => {
          const a_targetvalue = parseFloat(a.targetvalue)
          const b_targetvalue = parseFloat(b.targetvalue)
          if (a_targetvalue < b_targetvalue) {
            return -1
          }
          if (a_targetvalue > b_targetvalue) {
            return 1
          }
          return 0
        })
        this.$set(this.tabledata, index, this.tabledata[index])
        this.$emit('input', this.tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    savetemp_tabledatadata() {
      try {
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          thermometers: this.thermometers,
          data: this.tabledata,
          temp_data: this.temp_tabledata,
          parameter_data: this.parameter_tabledata,
          device_parameter_data: this.device_parameter_data,
        }
        this.$socket.emit('casecloud_setcasesregion_heater', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    savetabledata() {
      try {
        this.parameter_tabledata.forEach((element, index) => {
          if (element.name === 'operationtime') {
            if (
              new Date(`1990/01/01 ${element.from}`).getTime() >
              new Date(`1990/01/01 ${element.till}`).getTime()
            ) {
              const tmpdata = this.parameter_tabledata[index].till
              this.parameter_tabledata[index].till = element.from
              this.parameter_tabledata[index].from = tmpdata
            }
          }
        })
        this.device_parameter_data[this.devicesoptions_selected] = {
          temp_data: this.temp_tabledata,
          parameter_data: this.parameter_tabledata,
        }
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          thermometers: this.thermometers,
          data: this.tabledata,
          temp_data: this.temp_tabledata,
          parameter_data: this.parameter_tabledata,
          device_parameter_data: this.device_parameter_data,
        }
        this.$socket.emit('casecloud_setcasesregion_heater', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    timepickerinputHandler(event, index, key, datatype, enable) {
      const value = event.target.value
      console.log(value)
      this.parameter_tabledata[index][key] = value
      this.$set(
        this.parameter_tabledata,
        index,
        this.parameter_tabledata[index]
      )
      this.$emit('input', this.parameter_tabledata)
    },
    parameterinputHandler(event, index, key, datatype, enable) {
      const value = event.target.value
      this.parameter_tabledata[index][key] = value
      this.$set(
        this.parameter_tabledata,
        index,
        this.parameter_tabledata[index]
      )
      this.$emit('input', this.parameter_tabledata)
    },
    inputHandler(value, index, key, datatype, enable) {
      if (key === 'targetvalue') {
        const found = this.tabledata.findIndex((element, arrayindex) => {
          if (parseFloat(element.targetvalue) === parseFloat(value)) {
            return arrayindex
          }
          return false
        })
        if (found === -1) {
          this.tabledata[index].targetvalue = value
          this.tabledata[index].agename = value
        } else {
          this.tabledata.splice(found, 1)
        }
      } else {
        this.tabledata[index][key] = value
      }
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    inputheatlampHandler(value, index, key, datatype, enable) {
      let tmpvalue = 0
      if (key.indexOf('heatlamp') !== -1) {
        if (datatype === 'time') {
          tmpvalue = parseInt(value, 10)
          if (tmpvalue < 0) {
            tmpvalue = 0
          }
          if (tmpvalue > 864000) {
            tmpvalue = 864000
          }
        }
        if (enable === false) {
          this.tabledata[index][`${key}_close`] = tmpvalue
        } else {
          this.tabledata[index][`${key}_open`] = tmpvalue
        }
      }

      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    showdeletebutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }
      if (this.tabledata[index].targetvalue === 0) {
        return false
      }
      return true
    },
    showaddbutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }
      if (this.tabledata[index + 1] !== undefined) {
        if (
          parseFloat(this.tabledata[index + 1].targetvalue) ===
          parseFloat(this.tabledata[index].targetvalue) + 0.1
        ) {
          return false
        }
      }
      return true
    },
    addRowHandler(index) {
      const newRow = { ...this.tabledata[index] }
      newRow.targetvalue = parseFloat(this.tabledata[index].targetvalue) + 1
      newRow.agename = parseFloat(this.tabledata[index].agename) + 1
      newRow.isedit_age = true
      newRow.isedit_value = true
      this.tabledata.push(newRow)
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
    },
    removeRowHandler(index) {
      this.tabledata = this.tabledata.filter((item, i) => i !== index)
    },
    getTargetValue(value) {
      const vuethis = this
      const valueobj = {
        offsetvalue: value,
        value_start: vuethis.targetvalue_start,
        value_end: vuethis.targetvalue_end,
        age_start: vuethis.breedingAge_start,
        age_end: vuethis.breedingAge_end,
        age_current: vuethis.breedingAge_current,
      }
      try {
        return value
      } catch (error) {
        console.log(error)
        return value
      }
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = this.$route.params.casepn
        this.regionpn = this.$route.params.regionkey
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
        // setTimeout(vuethis.getCaseSMCDeviceList(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        const vuethis = this
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_heater',
            this.socketevent_casecloud_getcaseregion_heater
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesregion_heater')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_heater(params) {
      try {
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'devicecontrolsetting') {
              return
            }
          }
        }
        if (params === undefined || params.type === 'error') {
          return
        }
        if (
          params.callback_params !==
          `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`
        ) {
          return
        }
        if (params.msg !== undefined) {
          if (params.msg.data !== undefined) {
            if (params.msg.data.data !== undefined) {
              this.tabledata = params.msg.data.data
              this.tabledata.sort((a, b) => {
                const a_targetvalue = parseFloat(a.targetvalue)
                const b_targetvalue = parseFloat(b.targetvalue)
                if (a_targetvalue < b_targetvalue) {
                  return -1
                }
                if (a_targetvalue > b_targetvalue) {
                  return 1
                }
                return 0
              })
              vuethis.temp_tabledata = Object.assign(
                [],
                vuethis.temp_tabledata,
                params.msg.data.temp_data
              )
            }
            if (params.msg.data.parameter_data !== undefined) {
              vuethis.parameter_tabledata = Object.assign(
                [],
                vuethis.parameter_tabledata,
                params.msg.data.parameter_data
              )
            }
            if (params.msg.data.parameter_data !== undefined) {
              vuethis.device_parameter_data = Object.assign(
                vuethis.device_parameter_data,
                params.msg.data.device_parameter_data
              )
            }
          }
        }
        if (this.tabledata === undefined || this.tabledata === null) {
          this.tabledata = []
        }
        if (this.tabledata.length === 0) {
          this.tabledata = [
            {
              age: 0,
              targetvalue: 0,
              agename: '0',
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
            {
              age: 3650000,
              targetvalue: 3650000,
              agename: this.$t('common.infinitas'),
              isedit_age: false,
              isedit_value: false,
              ishide: true,
            },
          ]
        }
        if (
          this.parameter_tabledata === undefined ||
          this.parameter_tabledata === null
        ) {
          vuethis.parameter_tabledata = [
            {
              name: 'hy_value',
              value: 0.4,
            },
            {
              name: 'min_time',
              value: 120,
            },
            {
              name: 'operationtime',
              from: '00:00',
              till: '00:00',
            },
          ]
        }
        if (this.temp_tabledata === undefined || this.temp_tabledata === null) {
          this.temp_tabledata = []
        }
        if (this.temp_tabledata.length === 0) {
          this.temp_tabledata = [
            {
              age: 0,
              targetvalue: 0,
              agename: '0',
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
          ]
        }
      } catch (error) {
        console.log(error)
      }
    },
    getData() {
      try {
        const vuethis = this
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          callback_params: `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`,
        }
        this.$socket.emit('casecloud_getcasesregion_heater', queryParams)
        if (this.regiondevices.length > 0) {
          this.devicesoptions_selected = this.regiondevices[0].regiondevicekey
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss"></style>
