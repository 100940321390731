<template>
  <transition
    name="fade"
    class="w-100 d-flex row justify-content-between px-1"
    style="margin: 0px"
  >
    <div>
       <!-- 溫度計選擇 -->
      <b-col>
        <b-table
          ref="getTempTable"
          :items="temp_tabledata"
          :fields="temp_fields"
          :sort-desc="false"
          sort-icon-right
          striped
          :fixed="false"
          responsive
          class="mb-1"
        >
          <template #head()="scope">
            <div class="text-nowrap">
              {{ scope.label }}
            </div>
          </template>

          <!-- A virtual column -->
          <template #cell(index)="">
            <div class="text-center">
              {{ $t(`common.thermometer`) + $t(`common.select`) }}
            </div>
          </template>
          <template #cell()="data">
            <div
              class="d-flex justify-content-center align-items-center flex-column"
              @click="toggletemp(data.value, data.index, data.field.key)"
            >
              <template v-if="data.value === 1 || data.value === ''">
                <b-avatar class="" variant="primary" size="40">
                  <div>
                    <font-awesome-icon
                      style="font-size: 20px"
                      icon="fa-solid fa-temperature-three-quarters fa-lg"
                      class=""
                    />
                  </div>
                </b-avatar>
              </template>
              <template v-if="data.value === 0">
                <b-avatar class="" variant="light-secondary" size="40">
                  <div>
                    <font-awesome-icon
                      style="font-size: 20px"
                      icon="fa-solid fa-temperature-three-quarters fa-lg"
                      class=""
                    />
                  </div>
                </b-avatar>
              </template>
              <div>{{ getdevicelatestrawdata() }}</div>
            </div>
          </template>
        </b-table>
      </b-col>
      <hr />
      <b-col>
        <b-tabs fill pills class="mt-2">
          <b-tab title="通風設定" active>
            <!-- getFanTable  -->
            <b-table
              ref="getFanTable"
              :items="tabledata"
              :fields="fanfields"
              sort-by="age"
              :sort-desc="false"
              sort-icon-right
              striped
              :fixed="false"
              responsive
              class="mb-1"
            >
              <template #head()="scope">
                <div class="text-nowrap">
                  {{ scope.label }}
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                <div class="text-center">
                  {{ data.index + 1 }}
                </div>
              </template>
              <template #cell(targetvalue)="data">
                <div
                  class="d-flex justify-content-center align-items-center flex-row"
                >
                  <b-form-input
                    v-if="tabledata[data.index].isedit_value === true"
                    v-model="data.value"
                    type="number"
                    style="width: 75px; text-align: center"
                    @blur="inputHandler($event, data.index, data.field.key, 1)"
                  />
                  <b-form-input
                    v-else-if="
                      tabledata[data.index].isedit_value === false &&
                      data.value !== 3650000 &&
                      data.value !== -3650000
                    "
                    type="text"
                    style="width: 75px; text-align: center"
                    :value="data.value"
                    readonly
                  />
                  <div v-if="data.value === -3650000">
                    <b-form-input
                      :value="$t('common.negativeinfinitas')"
                      type="text"
                      style="width: 75px; text-align: center"
                      readonly
                    />
                  </div>

                  <div v-if="data.value !== 3650000">
~
</div>
                  <b-form-input
                    v-if="
                      data.value !== 3650000 &&
                      tabledata[data.index + 1].targetvalue !== 3650000
                    "
                    v-model="tabledata[data.index + 1].targetvalue"
                    type="text"
                    style="width: 75px; text-align: center"
                    readonly
                  />
                  <b-form-input
                    v-if="
                      data.value !== 3650000 &&
                      tabledata[data.index + 1].targetvalue === 3650000
                    "
                    :value="$t('common.infinitas')"
                    type="text"
                    style="width: 75px; text-align: center"
                    readonly
                  />
                </div>
              </template>

              <template #cell()="data">
                <template v-if="data.index < tabledata.length - 1">
                  <template
                    v-if="
                      data.field.type === 'inverter' ||
                      data.field.type === 'inverterfan'
                    "
                  >
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <!-- 運轉 -->
                      <template v-if="data.value === 1 || data.value === ''">
                        <div style="font-weight: 600; margin: 10px 0px">
                          固定轉速
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            @click="
                              toggleinverterfan(
                                data.value,
                                data.index,
                                data.field.key
                              )
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="40"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="
                                data.item[
                                  `${data.field.key}_open_rotatingspeed`
                                ]
                              "
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'rotatingspeed',
                                    true
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{
                                $t(`common.open`) +
                                ' (' +
                                $t(`common.rotatingspeed`) +
                                ')'
                              }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- 間歇1 -->
                      <template v-if="data.value === 2">
                        <div style="font-weight: 600; margin: 10px 0px">
                          溫度變速
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            @click="
                              toggleinverterfan(
                                data.value,
                                data.index,
                                data.field.key
                              )
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="35"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="
                                data.item[
                                  `${data.field.key}_open_rotatingspeed`
                                ]
                              "
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'rotatingspeed',
                                    true
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{ $t(`common.rotatingspeed`) }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                          style="margin-top: 10px"
                        >
                          <div
                            @click="
                              toggleinverterfan(
                                data.value,
                                data.index,
                                data.field.key
                              )
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="35"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="
                                data.item[
                                  `${data.field.key}_close_rotatingspeed`
                                ]
                              "
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'rotatingspeed',
                                    false
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{ $t(`common.rotatingspeed`) }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- 間歇2 -->
                      <template v-if="data.value === 3">
                        <div style="font-weight: 600; margin: 10px 0px">
                          間歇變速
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            @click="
                              toggleinverterfan(
                                data.value,
                                data.index,
                                data.field.key
                              )
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="35"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="
                                data.item[
                                  `${data.field.key}_open_rotatingspeed`
                                ]
                              "
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'rotatingspeed',
                                    true
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{ $t(`common.rotatingspeed`) }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="data.item[`${data.field.key}_open`]"
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'time',
                                    true
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{ $t(`common.seconds`) }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                          style="margin-top: 10px"
                        >
                          <div
                            @click="
                              toggleinverterfan(
                                data.value,
                                data.index,
                                data.field.key
                              )
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="35"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="
                                data.item[
                                  `${data.field.key}_close_rotatingspeed`
                                ]
                              "
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'rotatingspeed',
                                    false
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{ $t(`common.rotatingspeed`) }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="data.item[`${data.field.key}_close`]"
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputinverterFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'time',
                                    false
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{ $t(`common.seconds`) }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-if="data.field.type === 'fan'">
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <!-- 運轉 -->
                      <template v-if="data.value === 1">
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            @click="
                              togglefan(data.value, data.index, data.field.key)
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="40"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div style="font-size: 1rem; margin-left: 10px">
                            {{ $t(`common.open`) }}
                          </div>
                        </div>
                      </template>
                      <!-- 間歇 -->
                      <template v-if="data.value === 2">
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            @click="
                              togglefan(data.value, data.index, data.field.key)
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-success"
                              size="40"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class="fa-spin"
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="data.item[`${data.field.key}_open`]"
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'time',
                                    true
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{
                                $t(`common.open`) +
                                ' (' +
                                $t(`common.seconds`) +
                                ')'
                              }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                          style="margin-top: 10px"
                        >
                          <div
                            @click="
                              togglefan(data.value, data.index, data.field.key)
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-secondary"
                              size="40"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class=""
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="data.item[`${data.field.key}_close`]"
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 24px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                e =>
                                  inputFanHandler(
                                    e.target.value,
                                    data.index,
                                    data.field.key,
                                    'time',
                                    false
                                  )
                              "
                            />
                            <div style="font-size: 1rem">
                              {{
                                $t(`common.close`) +
                                ' (' +
                                $t(`common.seconds`) +
                                ')'
                              }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- 停 -->
                      <template v-if="data.value === 0 || data.value === ''">
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            @click="
                              togglefan(data.value, data.index, data.field.key)
                            "
                          >
                            <b-avatar
                              class=""
                              variant="light-secondary"
                              size="40"
                            >
                              <div>
                                <font-awesome-icon
                                  style="font-size: 20px"
                                  icon="fa-solid fa-fan fa-lg"
                                  class=""
                                />
                              </div>
                            </b-avatar>
                          </div>
                          <div style="font-size: 1rem; margin-left: 10px">
                            {{ $t(`common.close`) }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </template>
              </template>

              <template #cell(add)="data">
                <b-button
                  v-if="showaddbutton(data.index) == true"
                  variant="primary"
                  @click="addRowHandler(data.index)"
                >
                  <font-awesome-icon icon="fa-solid fa-plus" />
                </b-button>
              </template>
              <template #cell(delete)="data">
                <b-button
                  v-if="showdeletebutton(data.index) == true"
                  variant="danger"
                  @click="removeRowHandler(data.index)"
                >
                  <font-awesome-icon icon="fa-solid fa-trash" />
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="進氣設定">
            <!-- getInletTable  -->
            <b-table
              ref="getInletTable"
              :items="tabledata"
              :fields="lnletsfields"
              sort-by="age"
              :sort-desc="false"
              sort-icon-right
              striped
              :fixed="false"
              responsive
              class="mb-0"
            >
              <template #head()="scope">
                <div class="text-nowrap">
                  {{ scope.label }}
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                <div class="text-center">
                  {{ data.index + 1 }}
                </div>
              </template>
              <template #cell(targetvalue)="data">
                <div
                  class="d-flex flex-row justify-content-center align-items-center"
                >
                  <b-form-input
                    v-if="tabledata[data.index].isedit_value === true"
                    v-model="data.value"
                    type="number"
                    style="width: 75px; text-align: center"
                    readonly
                    @blur="inputHandler($event, data.index, data.field.key, 1)"
                  />
                  <b-form-input
                    v-else-if="
                      tabledata[data.index].isedit_value === false &&
                      data.value !== 3650000 &&
                      data.value !== -3650000
                    "
                    type="text"
                    style="width: 75px; text-align: center"
                    :value="data.value"
                    readonly
                  />

                  <div v-if="data.value === -3650000">
                    <b-form-input
                      :value="$t('common.negativeinfinitas')"
                      type="text"
                      style="width: 75px; text-align: center"
                      readonly
                    />
                  </div>
                  <div v-if="data.value !== 3650000">
~
</div>
                  <b-form-input
                    v-if="
                      data.value !== 3650000 &&
                      tabledata[data.index + 1].targetvalue !== 3650000
                    "
                    v-model="tabledata[data.index + 1].targetvalue"
                    type="number"
                    style="width: 75px; text-align: center"
                    readonly
                  />
                  <b-form-input
                    v-if="
                      data.value !== 3650000 &&
                      tabledata[data.index + 1].targetvalue === 3650000
                    "
                    :value="$t('common.infinitas')"
                    type="text"
                    style="width: 75px; text-align: center"
                    readonly
                  />
                </div>
              </template>

              <template #cell()="data">
                <template v-if="data.index < tabledata.length - 1">
                  <template
                    v-if="
                      data.field.type === 'inlet' ||
                      data.field.type === 'tunnel'
                    "
                  >
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <template>
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <div
                            class="d-flex flex-column justify-content-center align-items-center"
                          >
                            <b-form-input
                              v-model="data.item[`${data.field.key}_sv`]"
                              type="number"
                              style="
                                width: 85px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                height: 36px;
                                text-align: center;
                                margin-left: 10px;
                              "
                              @blur="
                                inputHandler(
                                  $event,
                                  data.index,
                                  data.field.key,
                                  1
                                )
                              "
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </template>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>

        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-button
            variant="success"
            style="margin: 10px 0px"
            @click="savetabledata()"
          >
            {{ $t(`common.save`) }}
          </b-button>
        </div>
      </b-col>
    </div>
  </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  VBTooltip,
  BCardBody,
  BTable,
  BFormCheckbox,
  BBadge,
  BButton,
  BFormInput,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

export default {
  components: {
    // BCard,
    BAvatar,
    // BRow,
    BCol,
    // BFormCheckbox,
    // BBadge,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondevices: {
      type: Array,
      default: () => [],
    },
    thermometers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagename: 'devicecontrolsetting_ventilation',
      random_number: 0,
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      isloading: false,
      casepn: '',
      regionpn: '',
      regiondeviceslist: {},
      targetvalue_start: 25,
      targetvalue_end: 24,
      breedingAge_start: 0,
      breedingAge_end: 35,
      breedingAge_current: 0,
      tabledata: [],
      temp_tabledata: [],
      inverterlist: [],
      lnletslist: [],
      fanlist: [],
    }
  },
  computed: {
    lnletsfields() {
      const tmpArray = []
      try {
        tmpArray.push({
          key: 'targetvalue',
          label: this.$t('common.temperature') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        const tmpregiondevicesArray = this.regiondevices

        tmpregiondevicesArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          const a_deviceindex = parseInt(a_name.replace(/[^0-9]/gi, ''), 10)
          const b_deviceindex = parseInt(b_name.replace(/[^0-9]/gi, ''), 10)
          if (a_deviceindex < b_deviceindex) {
            return -1
          }
          if (a_deviceindex > b_deviceindex) {
            return 1
          }
          return 0
        })

        tmpregiondevicesArray.forEach(element => {
          if (
            element.info.devicetype === 'inlet' ||
            element.info.devicetype === 'tunnel'
          ) {
            // console.log(element)
            tmpArray.push({
              key: `${element.info.devicetype}_${element.regiondevicekey}`,
              label: `${element.info.name} %`,
              type: element.info.devicetype,
              thStyle: {
                'min-width': '105px',
                'padding-right': '1rem',
                'text-align': 'center',
              },
            })
            this.lnletslist.push(
              `${element.info.devicetype}_${element.regiondevicekey}`
            )
          }
        })

        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
    fanfields() {
      const tmpArray = []
      try {
        // tmpArray.push({
        //   key: 'index',
        //   label: '#',
        //   type: 'number',
        //   thStyle: { 'max-width': '30px' },
        // })

        tmpArray.push({
          key: 'targetvalue',
          label: this.$t('common.temperature') + this.$t('common.value'),
          type: 'number',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        // tmpArray.push({
        //   key: 'windspeed',
        //   label: this.$t('common.windspeed'),
        //   type: 'number',
        //   thStyle: {
        //     'min-width': '105px',
        //     'padding-right': '1rem',
        //     'text-align': 'center',
        //   },
        // })
        // tmpArray.push({
        //   key: 'airvolume',
        //   label: this.$t('common.airvolume'),
        //   type: 'number',
        //   thStyle: {
        //     'min-width': '105px',
        //     'padding-right': '1rem',
        //     'text-align': 'center',
        //   },
        // })

        const tmpregiondevicesArray = this.regiondevices

        tmpregiondevicesArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          const a_deviceindex = parseInt(a_name.replace(/[^0-9]/gi, ''), 10)
          const b_deviceindex = parseInt(b_name.replace(/[^0-9]/gi, ''), 10)
          if (a_deviceindex < b_deviceindex) {
            return -1
          }
          if (a_deviceindex > b_deviceindex) {
            return 1
          }
          return 0
        })

        tmpregiondevicesArray.forEach(element => {
          if (
            element.info.devicetype === 'inverter' ||
            element.info.devicetype === 'inverterfan'
          ) {
            tmpArray.push({
              key: `${element.info.devicetype}_${element.regiondevicekey}`,
              label: element.info.name,
              type: element.info.devicetype,
              thStyle: {
                'min-width': '105px',
                'padding-right': '1rem',
                'text-align': 'center',
              },
            })
            this.inverterlist.push(
              `${element.info.devicetype}_${element.regiondevicekey}`
            )
          }
        })
        tmpregiondevicesArray.forEach(element => {
          if (element.info.devicetype === 'fan') {
            tmpArray.push({
              key: `${element.info.devicetype}_${element.regiondevicekey}`,
              label: element.info.name,
              type: element.info.devicetype,
              thStyle: {
                'min-width': '105px',
                'padding-right': '1rem',
                'text-align': 'center',
              },
            })
            this.fanlist.push(
              `${element.info.devicetype}_${element.regiondevicekey}`
            )
          }
        })

        tmpArray.push({ key: 'add', label: '', width: '84px' })
        tmpArray.push({ key: 'delete', label: '' })
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
    temp_fields() {
      const tmpArray = []
      try {
        tmpArray.push({
          key: 'index',
          label: '',
          type: 'text',
          thStyle: {
            'min-width': '105px',
            'padding-right': '1rem',
            'text-align': 'center',
          },
        })
        const tmpthermometersArray = this.thermometers

        tmpthermometersArray.sort((a, b) => {
          const a_name = a.info.name
          const b_name = b.info.name
          if (a_name < b_name) {
            return -1
          }
          if (a_name > b_name) {
            return 1
          }
          return 0
        })
        tmpthermometersArray.forEach(element => {
          if (element.info.devicetype === 'thermometer') {
            tmpArray.push({
              key: `${element.info.devicetype}_${element.regiondevicekey}`,
              label: element.info.name,
              type: element.info.devicetype,
              thStyle: {
                'min-width': '105px',
                'padding-right': '1rem',
                'text-align': 'center',
              },
            })
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return tmpArray
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
      clearInterval(this.interval_devicecloud_getdevice)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      clearInterval(this.interval_devicecloud_getdevice)
      document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getdevicelatestrawdata() {
      return ''
    },
    toggletemp(value, index, key) {
      try {
        if (key.indexOf('thermometer') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 0
          }
          if (value === 0) {
            fanstatus = 1
          } else if (value === 1) {
            fanstatus = 0
          }
          this.temp_tabledata[index][key] = fanstatus
        }

        this.$set(this.temp_tabledata, index, this.temp_tabledata[index])
        this.$emit('input', this.temp_tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    toggleinverterfan(value, index, key) {
      try {
        if (key.indexOf('inverter') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 1
          }
          if (value === 1) {
            if (index === 0) {
              fanstatus = 3
            } else {
              fanstatus = 2
            }
          } else if (value === 2) {
            fanstatus = 3
          } else if (value === 3) {
            fanstatus = 1
          }
          this.tabledata[index][key] = fanstatus
        }
        this.tabledata.sort((a, b) => {
          const a_targetvalue = parseFloat(a.targetvalue)
          const b_targetvalue = parseFloat(b.targetvalue)
          if (a_targetvalue < b_targetvalue) {
            return -1
          }
          if (a_targetvalue > b_targetvalue) {
            return 1
          }
          return 0
        })
        this.$set(this.tabledata, index, this.tabledata[index])
        this.$emit('input', this.tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    togglefan(value, index, key) {
      try {
        if (key.indexOf('fan') !== -1) {
          let fanstatus = value
          if (
            fanstatus === '' ||
            fanstatus === undefined ||
            fanstatus === null
          ) {
            fanstatus = 1
          }
          if (value === 0) {
            fanstatus = 1
          } else if (value === 1) {
            fanstatus = 2
          } else if (value === 2) {
            fanstatus = 0
          }
          this.tabledata[index][key] = fanstatus
        }
        this.tabledata.sort((a, b) => {
          const a_targetvalue = parseFloat(a.targetvalue)
          const b_targetvalue = parseFloat(b.targetvalue)
          if (a_targetvalue < b_targetvalue) {
            return -1
          }
          if (a_targetvalue > b_targetvalue) {
            return 1
          }
          return 0
        })
        this.$set(this.tabledata, index, this.tabledata[index])
        this.$emit('input', this.tabledata)
      } catch (error) {
        console.log(error)
      }
    },
    savetemp_tabledatadata() {
      try {
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          thermometers: this.thermometers,
          data: this.tabledata,
          temp_data: this.temp_tabledata,
        }
        this.$socket.emit('casecloud_setcasesregion_ventilation', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    savetabledata() {
      try {
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          regiondevices: this.regiondevices,
          thermometers: this.thermometers,
          data: this.tabledata,
          temp_data: this.temp_tabledata,
        }
        this.$socket.emit('casecloud_setcasesregion_ventilation', queryParams)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('common.setting_success'),
              icon: 'UserIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
            timeout: 1000,
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    inputHandler(event, index, key, pointlength) {
      const value = event.target.value
      if (key === 'targetvalue') {
        const found = this.tabledata.findIndex((element, arrayindex) => {
          if (parseFloat(element.targetvalue) === parseFloat(value)) {
            return arrayindex
          }
          return false
        })
        if (found === -1) {
          this.tabledata[index].targetvalue = parseFloat(value)
          this.tabledata[index].agename = parseFloat(value)
        } else {
          // this.tabledata.splice(found, 1)
        }
        this.tabledata[index][key] = parseFloat(value)
        this.tabledata[index].agename = parseFloat(value)
      } else {
        this.tabledata[index][key] = parseFloat(value)
      }
      let iserror = false
      if (key === 'targetvalue') {
        let isnum =
          /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/
        if (pointlength === 0) {
          isnum = /^-?\d+$/
        } else if (pointlength === 1) {
          isnum = /^-?\d+\.?\d{0,1}$/
        } else {
          isnum = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/
        }
        if (!isnum.test(value)) {
          this.$swal({
            icon: 'error',
            title: this.$t('error_common.input_error'),
            showConfirmButton: true,
            timer: 2000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          iserror = true
        }
      }
      // this.tabledata.sort((a, b) => {
      //   const a_targetvalue = parseFloat(a.targetvalue)
      //   const b_targetvalue = parseFloat(b.targetvalue)
      //   if (a_targetvalue < b_targetvalue) {
      //     return -1
      //   }
      //   if (a_targetvalue > b_targetvalue) {
      //     return 1
      //   }
      //   return 0
      // })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    inputinverterFanHandler(value, index, key, datatype, enable) {
      let tmpvalue = 0
      if (key.indexOf('inverter') !== -1) {
        if (datatype === 'rotatingspeed') {
          tmpvalue = parseInt(value, 10)
          if (tmpvalue < 0) {
            tmpvalue = 0
          }
          if (tmpvalue > 100) {
            tmpvalue = 100
          }
          if (enable === false) {
            this.tabledata[index][`${key}_close_rotatingspeed`] = tmpvalue
          } else {
            this.tabledata[index][`${key}_open_rotatingspeed`] = tmpvalue
          }
        }
        if (datatype === 'time') {
          tmpvalue = parseInt(value, 10)
          if (tmpvalue < 0) {
            tmpvalue = 0
          }
          if (tmpvalue > 864000) {
            tmpvalue = 864000
          }

          if (enable === false) {
            this.tabledata[index][`${key}_close`] = tmpvalue
          } else {
            this.tabledata[index][`${key}_open`] = tmpvalue
          }
        }
      }

      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    inputFanHandler(value, index, key, datatype, enable) {
      let tmpvalue = 0
      if (key.indexOf('fan') !== -1) {
        if (datatype === 'time') {
          tmpvalue = parseInt(value, 10)
          if (tmpvalue < 0) {
            tmpvalue = 0
          }
          if (tmpvalue > 864000) {
            tmpvalue = 864000
          }
        }
        if (enable === false) {
          this.tabledata[index][`${key}_close`] = tmpvalue
        } else {
          this.tabledata[index][`${key}_open`] = tmpvalue
        }
      }

      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.$set(this.tabledata, index, this.tabledata[index])
      this.$emit('input', this.tabledata)
    },
    showdeletebutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }
      if (this.tabledata[index + 1] !== undefined) {
        if (this.tabledata[index + 1].targetvalue === 3650000) {
          return false
        }
      }

      if (this.tabledata[index].targetvalue === -3650000) {
        return false
      }
      return true
    },
    showaddbutton(index) {
      if (this.tabledata[index].targetvalue === 3650000) {
        return false
      }

      if (this.tabledata[index].targetvalue === -3650000) {
        return false
      }
      if (this.tabledata[index + 1] !== undefined) {
        if (
          parseFloat(this.tabledata[index + 1].targetvalue) ===
          parseFloat(this.tabledata[index].targetvalue) + 0.1
        ) {
          return false
        }
      }
      return true
    },
    addRowHandler(index) {
      const newRow = { ...this.tabledata[index] }
      if (this.tabledata[index].targetvalue !== -3650000) {
        if (this.tabledata[index + 1] !== undefined) {
          if (
            parseFloat(this.tabledata[index + 1].targetvalue) ===
            parseFloat(this.tabledata[index].targetvalue) + 1
          ) {
            newRow.targetvalue =
              parseFloat(this.tabledata[index].targetvalue) + 0.1
          } else {
            newRow.targetvalue =
              parseFloat(this.tabledata[index].targetvalue) + 1
          }
        } else {
          newRow.targetvalue = parseFloat(this.tabledata[index].targetvalue) + 1
        }
      } else {
        newRow.targetvalue = 1
      }

      newRow.agename = newRow.targetvalue
      newRow.isedit_age = true
      newRow.isedit_value = true
      this.tabledata.push(newRow)
      this.tabledata.sort((a, b) => {
        const a_targetvalue = parseFloat(a.targetvalue)
        const b_targetvalue = parseFloat(b.targetvalue)
        if (a_targetvalue < b_targetvalue) {
          return -1
        }
        if (a_targetvalue > b_targetvalue) {
          return 1
        }
        return 0
      })
      this.tabledata.filter(
        (item, index2) => this.tabledata.indexOf(item.targetvalue) !== index2
      )
    },
    removeRowHandler(index) {
      if (this.tabledata[index].targetvalue === 1) {
        this.tabledata[index].targetvalue = 0
        return
      }
      if (this.tabledata.length === 3) {
        return
      }
      if (this.tabledata[index].targetvalue !== -3650000) {
        this.tabledata = this.tabledata.filter((item, i) => i !== index)
      }
      this.tabledata.filter(
        (item, index2) => this.tabledata.indexOf(item.targetvalue) !== index2
      )
    },
    page_initialize() {
      try {
        const vuethis = this
        this.casepn = this.$route.params.casepn
        this.regionpn = this.$route.params.regionkey
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
        // setTimeout(vuethis.getCaseSMCDeviceList(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        const vuethis = this
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_ventilation',
            this.socketevent_casecloud_getcaseregion_ventilation
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesregion_ventilation')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_ventilation(params) {
      try {
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'devicecontrolsetting') {
              return
            }
          }
        }
        if (params === undefined || params.type === 'error') {
          return
        }
        if (
          params.callback_params !==
          `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`
        ) {
          return
        }
        if (params.msg !== undefined) {
          if (params.msg.data !== undefined) {
            if (params.msg.data.data !== undefined) {
              this.tabledata = params.msg.data.data
              this.tabledata.sort((a, b) => {
                const a_targetvalue = parseFloat(a.targetvalue)
                const b_targetvalue = parseFloat(b.targetvalue)
                if (a_targetvalue < b_targetvalue) {
                  return -1
                }
                if (a_targetvalue > b_targetvalue) {
                  return 1
                }
                return 0
              })
              this.temp_tabledata = params.msg.data.temp_data
            }
          }
        }
        if (this.tabledata === undefined || this.tabledata === null) {
          this.tabledata = []
        }
        if (this.tabledata.length === 0) {
          this.tabledata = [
            {
              age: 0,
              targetvalue: -3650000,
              agename: -3650000,
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
            {
              age: 0,
              targetvalue: 0,
              agename: 0,
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
            {
              age: 3650000,
              targetvalue: 3650000,
              agename: this.$t('common.infinitas'),
              isedit_age: false,
              isedit_value: false,
              ishide: true,
            },
          ]
        }
        if (this.temp_tabledata === undefined || this.temp_tabledata === null) {
          this.temp_tabledata = []
        }
        if (this.temp_tabledata.length === 0) {
          this.temp_tabledata = [
            {
              age: 0,
              targetvalue: 0,
              agename: '0',
              isedit_age: false,
              isedit_value: false,
              ishide: false,
            },
          ]
        }
      } catch (error) {
        console.log(error)
      }
    },
    getData() {
      try {
        const vuethis = this
        const queryParams = {
          casepn: this.casepn,
          regionpn: this.regionpn,
          callback_params: `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionpn}_${vuethis.random_number}`,
        }

        this.$socket.emit('casecloud_getcasesregion_ventilation', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">

</style>
